import React from "react";
import "./AboutPageContent.css"; // Import the CSS file

const AboutPageContent = () => {
  return (
    <div id="about-page-container">
      <h1>About This Language Learning Page</h1>
      <p>
        This Language Learning page allows you to learn a new language by going
        through dialogues. You can select a conversation from the dropdown, and
        the dialogue will be displayed. Use the navigation buttons to move
        between conversations and lines within a conversation.
      </p>
      <h2>Features</h2>
      <ul>
        <li>
          <strong>Dynamic Content:</strong> Dialogues are dynamically fetched
          based on the selected language. This ensures that users always have
          access to the most relevant and up-to-date content for their language
          learning needs. By fetching dialogues dynamically, the application can
          support multiple languages without requiring manual updates for each
          language.
        </li>
        <li>
          <strong>Learner Names:</strong> Dialogues are personalized by
          including learner names fetched from an API. The application modifies
          the dialogues to replace placeholders with the actual names of the
          learners. This personalization makes the learning experience more
          engaging and relatable, helping learners feel more connected to the
          content and improving retention and comprehension.
        </li>
        <li>
          <strong>Navigation:</strong> Users can navigate between different
          conversations and lines within a conversation using navigation
          buttons. This allows users to move forward and backward through the
          dialogues at their own pace, providing a seamless and user-friendly
          experience. It enhances the overall usability of the application and
          supports self-paced learning.
        </li>
        <li>
          <strong>Modal:</strong> A modal is used to display additional
          information about the page. The modal can be triggered by a button and
          provides a focused view of the content without navigating away from
          the main page. Using a modal helps keep the main page clean and
          uncluttered, allowing users to access important information without
          losing their place in the dialogue.
        </li>
      </ul>
      <h2>How It Works</h2>
      <p>
        The application uses the MERN stack (MongoDB, Express, React, Node.js)
        to provide a dynamic and interactive language learning experience. Here
        is a brief overview of how it works:
      </p>
      <ol>
        <li>
          <strong>Fetching Data:</strong> When the page loads, the application
          fetches learners and dialogue data. The learners are fetched from an
          API, and the dialogue is fetched from a text file based on the
          selected language. Fetching data dynamically ensures that the
          application always has the latest information, allowing for real-time
          updates and scalability.
        </li>
        <li>
          <strong>Modifying Dialogue:</strong> The dialogue is modified to
          include learner names. This is done by replacing the text before the
          colon with learner names. Personalizing the dialogue with learner
          names makes the content more engaging and relevant to the user,
          helping learners feel more involved in the learning process.
        </li>
        <li>
          <strong>Rendering Dialogue:</strong> The modified dialogue is split
          into conversations and displayed on the page. Users can navigate
          between conversations and lines within a conversation. Splitting the
          dialogue into manageable chunks makes it easier for users to follow
          and understand, while the navigation feature allows users to control
          their learning pace and review content as needed.
        </li>
        <li>
          <strong>Modal:</strong> The modal displays additional information
          about the page, including the `AboutPageContent` component. The modal
          provides a convenient way to access supplementary information without
          leaving the main page, enhancing the user experience by offering
          context and details in a focused view.
        </li>
      </ol>
      <h2>Available Languages At Present</h2>
      <p>The application currently supports only in : English</p>
      <ul>
        
        {/* <li>Kannada</li>
        <li>Hindi</li>
        <li>Tamil</li> */}
      </ul>
      <h2>Next Plan</h2>
      <p>
        Our next plan is to expand the available scripts to include every
        language. This will allow users to learn a wider variety of languages
        and access more diverse content. We are working on sourcing and
        integrating scripts for multiple languages to enhance the learning
        experience.
      </p>
      <p>
        Additionally, we are planning to enhance the application to support a
        collaborative learning environment where friends can study together from
        different locations. This feature will enable users to connect with
        their friends, share progress, and engage in interactive learning
        sessions, making the language learning experience more social and
        enjoyable.
      </p>
      <h2>Invitation to Developers</h2>
      <p>
        We are excited to invite developers who are passionate about language
        learning and innovative educational tools to collaborate with us on this
        open-source project. Your contributions can help take this language
        learning tool to the next level, making it more accessible, engaging,
        and effective for learners around the world.
      </p>
      <p>
        If you are interested in contributing, please visit our GitHub
        repository to get started. We welcome developers of all skill levels and
        backgrounds to join us in this exciting journey. Together, we can create
        a powerful and innovative language learning platform that empowers
        learners everywhere.
      </p>
      <p style={{ textAlign: "center" }}>
        <a
          href="https://github.com/atozats/GLearn.git"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit our GitHub repository
        </a>
      </p>
    </div>
  );
};

export default AboutPageContent;
