import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Trash2 } from "lucide-react"; // Import Lucide React icons
import "./LearnHome.css";
import { AuthContext } from "../../Contexts/AuthContext";
import api from "../../Utils/Api";
import { toast } from "react-toastify";
import FullScreenDropdown from "../../components/FullScreenDropdown/FullScreenDropdown";

const LearnHome = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  // State variables
  const [languages, setLanguages] = useState([]);
  const [learnerName, setLearnerName] = useState("");
  const [learners, setLearners] = useState([]);
  const [selectedLearners, setSelectedLearners] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [deletePopup, setDeletePopup] = useState({
    show: false,
    learnerId: null,
    learnerName: "",
  });

  // Fetch learners on component mount
  useEffect(() => {
    fetchLanguages();
    fetchLearners();
  }, []);

  // Fetch learners from the server
  const fetchLearners = async () => {
    try {
      const response = await api.get(`/api/Learn/users/learners`);
      const data = await response.data;

      if (Array.isArray(data)) {
        setLearners(data);
      } else {
        setLearners([]);
      }
    } catch (error) {
      console.error("Error fetching learners:", error);
      setLearners([]);
    }
  };

  //fetch languages
  const fetchLanguages = async () => {
    try {
      const response = await api.get(`/api/language/getList`);
      const data = await response.data;
      setLanguages(data);
    } catch (error) {
      console.error("Error fetching languages:", error);
      setLanguages([]);
    }
  };

  // Add a new learner
  const handleAddLearner = async () => {
    if (!learnerName.trim()) {
      toast.warning("Please enter a learner's name.");
      return;
    }

    if (!user || !user.id) {
      toast.error("User ID not found. Please log in again.");
      navigate("/auth");
      return;
    }

    try {
      const response = await api.post(
        `/api/Learn/users/learners`,
        JSON.stringify({ feature: learnerName }),
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 201) {
        toast.success("Learner added successfully.");
        fetchLearners();
        setLearnerName("");
      } else {
        toast.error("Failed to add learner.");
      }
    } catch (error) {
      console.error("Error adding learner:", error);
      toast.error("Error adding learner. Check the console for more details.");
    }
  };

  // Delete a learner
  const handleDeleteLearner = async (learnerId) => {
    try {
      const response = await api.delete(
        `/api/Learn/users/learners/${learnerId}`
      );
      console.log("Server Response:", response);

      if (response.status === 200) {
        toast.success("Learner deleted successfully.");
        fetchLearners();
        setDeletePopup({ show: false, learnerId: null, learnerName: "" });
      } else {
        alert("Failed to delete learner.");
      }
    } catch (error) {
      console.error("Error deleting learner:", error);
    }
  };

  // Select or deselect a learner
  const handleSelectLearner = (learnerId) => {
    setSelectedLearners((prevSelected) => {
      const updatedSelected = prevSelected.includes(learnerId)
        ? prevSelected.filter((id) => id !== learnerId)
        : [...prevSelected, learnerId];

      localStorage.setItem("selectedLearners", JSON.stringify(updatedSelected));
      return updatedSelected;
    });
  };

  // Select or deselect all learners
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const allLearnerIds = learners.map((learner) => learner._id);
      setSelectedLearners(allLearnerIds);
      localStorage.setItem("selectedLearners", JSON.stringify(allLearnerIds));
    } else {
      setSelectedLearners([]);
      localStorage.setItem("selectedLearners", JSON.stringify([]));
    }
  };

  const conntinueToDialogues = () => {
    console.log("conntinueToDialogues");

    if (selectedLearners.length === 0) {
      toast.warning("Please select at least one learner.");
    } else {
      navigate(`/learn/dialogue/${selectedLanguage}`);
    }
  };

  // Show delete confirmation popup
  const confirmDeletePopup = (learnerId, learnerName) => {
    setDeletePopup({ show: true, learnerId, learnerName });
  };

  // Cancel delete confirmation popup
  const cancelDelete = () => {
    setDeletePopup({ show: false, learnerId: null, learnerName: "" });
  };

  // Calculate attendance details
  const totalLearners = learners.length;
  const presentLearners = selectedLearners.length;
  const absentLearners = totalLearners - presentLearners;

  return (
    <div className="home-container">
      <h3 className="home-heading"> Speak Freely, Connect Globally</h3>

      <div className="topbar">
        <div className="input-container">
          <input
            id="learner-input"
            type="text"
            placeholder="Enter Learner's Name"
            value={learnerName}
            onChange={(e) => setLearnerName(e.target.value)}
            className="border p-2 rounded mr-2"
          />
          <button
            id="add-learner-btn"
            onClick={handleAddLearner}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Learner
          </button>
        </div>
        <div className="dd-language-select flex justify-center">
          <FullScreenDropdown
            placeholder="Select a language to start learning"
            label=""
            dataSource={languages}
            fields={{ id: "display_name", text: "display_name" }}
            onSelected={(selectedItem) =>
              setSelectedLanguage(selectedItem.display_name)
            }
          />
        </div>
        <div className="continue-btn-container">
          <button
            id="continue-btn"
            onClick={conntinueToDialogues}
            className="bg-green-500 text-white rounded hover:bg-green-600"
          >
            Start learning
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">
              <input
                id="chk-select-all-learners"
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="mr-2"
              />
              Select All
            </th>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {learners.length > 0 ? (
            learners.map((learner) => (
              <tr key={learner._id}>
                <td className="py-2 px-4 border-b text-center">
                  <input
                    type="checkbox"
                    checked={selectedLearners.includes(learner._id)}
                    onChange={() => handleSelectLearner(learner._id)}
                  />
                </td>
                <td className="py-2 px-4 border-b">{learner.feature}</td>
                <td className="py-2 px-4 border-b text-center flex justify-center">
                  <Trash2
                    className="text-red-500 cursor-pointer"
                    onClick={() =>
                      confirmDeletePopup(learner._id, learner.feature)
                    }
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan="3"
                className="py-2 px-4 border-b text-center text-gray-500"
              >
                No learners added yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>Please select learners......</p>
            <button
              onClick={() => setShowPopup(false)}
              className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              OK
            </button>
          </div>
        </div>
      )}

      {deletePopup.show && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <p>
              {" "}
              Are you sure you want to delete "{deletePopup.learnerName}" ?
            </p>
            <div className="mt-2 space-x-2">
              <button
                onClick={() => handleDeleteLearner(deletePopup.learnerId)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Yes
              </button>
              <button
                onClick={cancelDelete}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearnHome;
