import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-6">About Us</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
        <p>
          Empowering communities worldwide to bridge language barriers through a collaborative and innovative translation platform. We strive to create an inclusive space where language enthusiasts can contribute to global understanding and communication.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
        <p>
          To become the leading platform for crowd-sourced translations, fostering cultural exchange and making multilingual communication accessible to all.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What We Do</h2>
        <ul className="list-disc pl-5">
          <li><strong>Text Translation:</strong> Contribute to translations across various languages and dialects.</li>
          <li><strong>Audio Translation:</strong> Record and upload audio translations to aid pronunciation and accessibility.</li>
          <li><strong>Community Collaboration:</strong> Engage with fellow contributors and linguists to enhance translation accuracy and cultural relevance.</li>
          <li><strong>Open Source App:</strong> Our platform is fully open-sourced, allowing developers to contribute to the app's growth and improvement.</li>
          <li><strong>Language Learning Page:</strong> Access resources and tools to help you learn new languages effectively.</li>
          <li><strong>Multilingual Typing Page:</strong> Type in different languages with ease using our specialized typing interface.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p>Email: <a href="mailto:info.gtongue@atozas.com" className="text-blue-500">info.gtongue@atozas.com</a></p>
        {/* <p>Phone: +1 (123) 456-7890</p> */}
        <p>Address: F-18, Phase-6, Ayanagar, New Delhi, India PIN: 110047</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Join Us</h2>
        <p>Be part of a global community breaking language barriers. Your contributions make a difference!</p>
        <a href="https://github.com/atozats/Gtongue_MERN/blob/main/.github/CLA.md" className="text-blue-500 hover:underline">Click here to view the Contributor License Agreement (CLA)</a>
        <p>and start contributing today!</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Explore Our GitHub Repository</h2>
        <p>Discover the code behind our platform, contribute to its development, and join our community of developers and language enthusiasts.</p>
        <a href="https://github.com/atozats/Gtongue_MERN" className="text-blue-500 hover:underline">Check out our GitHub repository</a>
        <p>and be a part of something amazing!</p>
      </section>
    </div>
  );
};

export default AboutUs;