import React, { useEffect, useState } from 'react';
import Api from '../../Utils/Api.js';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useLoading } from '../../Contexts/LoadingContext';
import UserFeedbacks from '../../components/UserFeedbacks';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loginLogs, setLoginLogs] = useState([]);
  const [translationCount, setTranslationCount] = useState(0); // State for translation count
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      try {
        const res = await Api.get('/api/auth/profile');
        setUser(res.data.user);
        setLoginLogs(res.data.loginLogs);
        setTranslationCount(res.data.translationCount); // Set translation count
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error('Failed to fetch profile.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const columns = [
    {
      name: 'IP Address',
      selector: (row) => row.ipAddress,
    },
    {
      name: 'User Agent / Browser',
      selector: (row) => {
        const userAgent = row.userAgent;
        if (userAgent.includes('Edg/')) {
          return 'Microsoft Edge';
        } else if (userAgent.includes('OPR/')) {
          return 'Opera';
        } else if (userAgent.includes('Firefox/')) {
          return 'Mozilla Firefox';
        } else if (userAgent.includes('Chrome/')) {
          return 'Google Chrome';
        } else {
          return 'Unknown Browser';
        }
      },
    },
    {
      name: 'Timestamp',
      selector: (row) => new Date(row.timestamp).toLocaleString(),
      sortable: true,
    },
  ];

  return (
    <div className="container mx-auto mt-8 p-4">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Profile</h2>

      {user && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Personal Information</h3>
          <p className="text-gray-600">
            <strong className="font-medium">Username:</strong> {user.username}
          </p>
          <p className="text-gray-600">
            <strong className="font-medium">Email:</strong> {user.email}
          </p>
          <p className="text-gray-600">
            <strong className="font-medium">Role:</strong> {user.isAdmin ? 'Admin' : 'User'}
          </p>
          <p className="text-gray-600">
            <strong className="font-medium">Translated Records:</strong> {translationCount}
          </p>
        </div>
      )}

      {/* Responsive Grid for Feedbacks and Login Activity */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* User Feedbacks Section */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">My Feedbacks</h3>
          <UserFeedbacks />
        </div>

        {/* Login Activity Section */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Login Activity</h3>
          <DataTable
            columns={columns}
            data={loginLogs}
            progressPending={isLoading}
            pagination={false}
            highlightOnHover
            responsive
            striped
            customStyles={{
              rows: {
                style: {
                  minHeight: '48px', // Override the row height
                },
              },
              headCells: {
                style: {
                  backgroundColor: '#f9fafb',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#374151',
                },
              },
              cells: {
                style: {
                  fontSize: '14px',
                  color: '#4b5563',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;