import { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { PlayCircle } from "lucide-react";
import FullScreenDropdown from "../../components/FullScreenDropdown/FullScreenDropdown";
import transliterationEnableLanguages from "../../Common/transliteration_enabled_languages.json";
import api from "../../Utils/Api";

const TranslatePage = () => {
  const [inputText, setInputText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [fromLanguage, setFromLanguage] = useState("");
  const [toLanguage, setToLanguage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [translatedArray, setTranslatedArray] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // State to track if audio is playing

  //for transliteration
  const lanControl = useRef(null);
  const [transliterationReady, setTransliterationReady] = useState(false);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const res = await api.get("/api/language/getList");
        setLanguages(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchLanguages();
  }, []);

  useEffect(() => {
    let googleScript;
    const loadGoogleApi = () => {
      return new Promise((resolve) => {
        if (window.google?.elements?.transliteration) {
          resolve();
          return;
        }
  
        googleScript = document.createElement("script");
        googleScript.src = "https://www.google.com/jsapi";
        googleScript.async = true;
        googleScript.onload = () => {
          window.google.load("elements", "1", {
            packages: "transliteration",
            callback: resolve,
          });
        };
        document.body.appendChild(googleScript);
      });
    };
  
    loadGoogleApi().then(() => {
      setTransliterationReady(true);
    });
  
    return () => {
      lanControl.current?.disableTransliteration();
      if (googleScript) document.body.removeChild(googleScript);
    };
  }, []);
  
  useEffect(() => {
    if (!transliterationReady) return;
  
    // Clean previous instance
    lanControl.current?.disableTransliteration();
    lanControl.current = null;
  
    const transCode = isLanTransliterable(fromLanguage?.display_name);
    if (transCode) {
      const control = new window.google.elements.transliteration.TransliterationControl({
        sourceLanguage: "en",
        destinationLanguage: [transCode],
        shortcutKey: "ctrl+g",
        transliterationEnabled: true,
      });
      control.makeTransliteratable(["input_textarea"]);
      lanControl.current = control;
    }
  }, [fromLanguage, transliterationReady]);
  

  const handleTranslate = async () => {
    if (!inputText){
      toast.error("Please enter text to translate.");
      return;
    } 
    if(!fromLanguage) {
      toast.error("Please select a source language.");
      return;
    } 
    if(!toLanguage) {
      toast.error("Please select a target language.");
      return;
    }
    if (fromLanguage.display_name === toLanguage.display_name) {
      toast.error("Please select different languages.");
      return;
    }
    setLoading(true);
    try {
      let res = await api.post("/api/translation/translate", {
        fromText: inputText,
        from: fromLanguage.language,
        to: toLanguage.language,
      });

      if (res.data.translated_array.length > 0) {
        setTranslatedArray(res.data.translated_array); // Set translated array
        let newTranslation = res.data.translated_array
          .map((item) => item.lan_text)
          .join(" ");
        setTranslatedText(newTranslation);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const swapLanguages = () => {
    // Store current values in temporary variables
    const tempFromLanguage = fromLanguage;
    const tempToLanguage = toLanguage;
    const tempInputText = inputText;
    const tempTranslatedText = translatedText;

    // Swap languages
    setFromLanguage(tempToLanguage);
    setToLanguage(tempFromLanguage);

    // Swap input and translated text
    setInputText(tempTranslatedText);
    setTranslatedText(tempInputText);
  };

  const playAllAudio = async () => {
    setIsPlaying(true); // Disable the button while playing
    for (let i = 0; i < translatedArray.length; i++) {
      const item = translatedArray[i];
      if (item.audio) {
        await playAudioWithHighlight(item.audio, item.lan_text);
      }
    }
    setIsPlaying(false); // Re-enable the button after playing
  };

  const playAudioWithHighlight = (base64Audio, text) => {
    return new Promise((resolve, reject) => {
      if (!base64Audio) {
        console.error("Invalid audio data");
        return reject();
      }

      try {
        // Decode base64 string to binary data
        const binaryString = atob(base64Audio);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const audioBlob = new Blob([bytes], { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        audio.addEventListener('play', () => {
          highlightText(text);
        });

        audio.addEventListener('ended', () => {
          removeHighlight();
          resolve();
        });

        audio.addEventListener('error', (e) => {
          console.error("Error playing audio:", e);
          reject();
        });

        audio.play();
      } catch (error) {
        console.error("Error playing audio:", error);
        reject();
      }
    });
  };

  const highlightText = (text) => {
    const div = document.getElementById("translation-display");
    if (div) {
      const regex = new RegExp(`(${text})`, "gi");
      div.innerHTML = div.innerText.replace(regex, `<mark>$1</mark>`);
    }
  };
  
  const removeHighlight = () => {
    const div = document.getElementById("translation-display");
    if (div) {
      div.innerHTML = div.innerText; // Reset
    }
  };

  const isLanTransliterable = (language) => {
    if (!language || language === 'English') return false;
    const code = transliterationEnableLanguages.find(lan => lan.language === language)?.code;
    return code ?? false;
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Translate</h2>
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <FullScreenDropdown
                label="From"
                dataSource={languages}
                fields={{ id: "display_name", text: "display_name" }}
                onSelected={(lang) => setFromLanguage(lang)}
                selectedValue={fromLanguage}
              />
              <textarea
                id="input_textarea"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Enter text to translate..."
                className="w-full h-32 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              ></textarea>
            </div>

            <button
              className="text-2xl mx-4 transform rotate-90 md:rotate-0"
              onClick={swapLanguages}
            >
              &#8646;
            </button>

            <div className="w-full md:w-1/2">
              <FullScreenDropdown
                label="To"
                dataSource={languages}
                fields={{ id: "display_name", text: "display_name" }}
                onSelected={(lang) => setToLanguage(lang)}
                selectedValue={toLanguage}
              />
              <div className="relative">
                <div
                  id="translation-display"
                  contentEditable={false}
                  placeholder="Translation will appear here..."
                  className="w-full h-32 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 overflow-auto"
                >
                  {translatedText}
                </div>
                <div
                  className={`absolute bottom-2 right-2 cursor-pointer ${
                    isPlaying ? "text-gray-400" : "text-blue-800"
                  }`}
                  onClick={!isPlaying ? playAllAudio : null} 
                >
                  <PlayCircle
                    className={`w-6 h-6 ${isPlaying ? "animate-spin" : ""}`} 
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
            onClick={handleTranslate}
          >
            {loading ? "Translating..." : "Translate"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranslatePage;
