import React, { useState } from 'react';
import api from '../../Utils/Api';
import { toast } from 'react-toastify';

const CLASign = () => {
    const [username, setUsername] = useState('');

    const handleSign = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/api/github/cla-sign', { username });
            toast.success(response.data.message);
        } catch (err) {
            toast.error(err.response.data.error);
            console.error(err);
        }
    };

    const handleCheck = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/api/github/check-cla', { username });
            if (response.data.signed) {
                toast.success('You have already signed the CLA.');
            } else {
                toast.warn('You have not signed the CLA.');
            }
        } catch (err) {
            console.error(err.response.data.error);
            toast.error('Error checking CLA status.');
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <h1 className="text-2xl font-bold mb-6 text-center">Sign Contributor License Agreement (CLA)</h1>
            <form onSubmit={handleSign} className="mb-4">
                <div className="mb-4">
                    <label htmlFor="username" className="block text-sm font-medium text-gray-700">GitHub Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200"
                >Sign CLA
                </button>
            </form>
            <button
                onClick={handleCheck}
                className="w-full bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-200"
            >Check if Signed
            </button>

            <p className="mt-4 text-center">
                <a href={`https://github.com/atozats/Gtongue_MERN/blob/main/.github/CLA.md`} className="text-blue-500 hover:underline">Click here to view the Contributor License Agreement (CLA)</a>.
            </p>
        </div>
    );
};

export default CLASign;