import axios from "axios";
import { useNavigate } from 'react-router-dom';

const api = axios.create();

// Setup an interceptor to add the token to the request header
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Set up an interceptor to handle responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // If we've reached here, the token has expired / not sent / invalid
      // log out the user
      localStorage.removeItem('token');
      // Redirect to login page
      const navigate = useNavigate();
      navigate('/auth');
    }
    return Promise.reject(error);
  }
);

export default api;