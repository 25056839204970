import React, { useState } from 'react';
import TranslationStats from '../../components/Admin/TranslationStats';
import MonthlyActivityChart from '../../components/Admin/MonthlyActivityChart';
import UserList from '../../components/Admin/UserList';
import Feedbacks from '../../components/Admin/Feedbacks';
import LanguageList from '../../components/LanguageList';
// import FeedbackList from '../../components/Admin/FeedbackList'; 
// import ReportList from '../../components/Admin/ReportList';
// import AdminSettings from '../../components/Admin/AdminSettings';

function AdminDashboard() {
    const [activeTab, setActiveTab] = useState('translationStats'); // State to manage active tab

    return (
        <div>
           <div className="p-6 rounded-lg shadow-md mb-3">
    <h1 className="text-3xl font-bold text-center">Admin Dashboard</h1>
</div>

            {/* Tabs */}
            <div className="bg-gray-100 bg-opacity-70 p-6 rounded-lg shadow-md">

                {/* tab headers */}
                <div className="border-b mb-4 flex gap-4">
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'translationStats' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('translationStats')}
                    >
                        Translation Statistics
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'monthlyActivity' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('monthlyActivity')}
                    >
                        Monthly Activity
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'manageUsers' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('manageUsers')}
                    >
                        Manage Users
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'viewFeedbacks' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('viewFeedbacks')}
                    >
                        Feedbacks
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'languagesList' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('languagesList')}
                    >
                        Languages List
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'viewReports' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('viewReports')}
                    >
                        View Reports
                    </button>
                    <button
                        className={`px-4 py-2 text-sm font-medium ${activeTab === 'adminSettings' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-500'
                            }`}
                        onClick={() => setActiveTab('adminSettings')}
                    >
                        Admin Settings
                    </button>
                </div>

                {/* Tab Content */}
                <div>
                    {activeTab === 'translationStats' && (
                        <div id="Translation-stats" className="container mt-4">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Translation Statistics</h2>
                            <TranslationStats />
                        </div>
                    )}
                    {activeTab === 'monthlyActivity' && (
                        <div id="monthly-activity" className="container mt-4">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Monthly Activity</h2>
                            <MonthlyActivityChart />
                        </div>
                    )}
                    {activeTab === 'manageUsers' && (
                        <div id="manage-users" className="container mt-4">
                            <UserList />
                        </div>
                    )}
                    {activeTab === 'viewFeedbacks' && (
                        <div id="view-feedbacks" className="container mt-4">
                            <Feedbacks />
                        </div>
                    )}
                    {activeTab === 'languagesList' && (
                        <div id="languages-list" className="container mt-4">
                            <LanguageList />
                        </div>
                    )}
                    {activeTab === 'viewReports' && (
                        <div id="view-reports" className="container mt-4">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-4">View Reports</h2>
                            <p>Reports content goes here...</p>
                        </div>
                    )}
                    {activeTab === 'adminSettings' && (
                        <div id="admin-settings" className="container mt-4">
                            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Admin Settings</h2>
                            <p>Admin settings content goes here...</p>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
}

export default AdminDashboard;