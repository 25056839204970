import React from "react";

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    background: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "70vw",
    width: "100%",
    height: "90vh",
    overflowY: "auto",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    position: "relative",
    animation: "fadeIn 0.3s ease-in-out",
  },
  modalCloseButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "#ff4d4d", // Red background
    border: "none",
    color: "white", // White text color
    fontSize: "24px",
    fontWeight: "bold",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background 0.3s ease",
  },
  modalCloseButtonHover: {
    background: "#ff1a1a", // Darker red on hover
  },
  modalContent: {
    marginTop: "20px",
  },
};

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modal}>
        <button
          style={styles.modalCloseButton}
          onMouseEnter={(e) =>
            (e.target.style.background =
              styles.modalCloseButtonHover.background)
          }
          onMouseLeave={(e) =>
            (e.target.style.background = styles.modalCloseButton.background)
          }
          onClick={onClose}
        >
          &times;
        </button>
        <div style={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
