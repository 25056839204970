import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function LandingPage() {

  const navigate = useNavigate();


  return (
    <main className="min-h-screen text-gray-800 p-6 flex flex-col items-center">

      {/* Logo and Header */}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-center my-3">
        {/* <img src="/GTongue_logo.png" alt="App Logo" className="w-24 h-24 mx-auto mb-4" /> */}
        <h1 className="text-4xl font-bold mb-2">GTongue - The Global Tongue</h1>
        <p className="text-lg text-gray-600 max-w-xl mx-auto">
          Breaking Language Barriers, Uniting the World!
        </p>
      </motion.div>

      {/* About and How It Works Section */}
      <div className="bg-gray-50 p-6 rounded-xl shadow-md max-w-5xl w-full mb-10">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">About GTongue</h2>
          <p className="text-gray-700">
            GTongue is a revolutionary platform designed to eliminate language barriers. Our goal is to enable global communication by providing an extensive repository of translations, created and shared by people worldwide.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-2">How It Works</h2>
          <div className="grid gap-4 md:grid-cols-3">
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-bold mb-1">📖 Text Translation</h3>
              <p>Type or paste text, and GTongue instantly translates it into any language.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-bold mb-1">🔊 Voice Communication</h3>
              <p>Soon, real-time voice translation will allow you to communicate effortlessly across languages.</p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-bold mb-1">🌎 Global Access</h3>
              <p>No matter where you are, GTongue helps you understand and be understood.</p>
            </div>
          </div>
        </section>

        <section className="text-center">
          <h2 className="text-xl font-bold mb-2">Join the GTongue Revolution</h2>
          <p className="mb-4">Start communicating without language barriers today!</p>
          <Button  onClick={() => navigate('/Contribute')}>Contribute</Button>
        </section>
      </div>

      {/* Core Feature Navigation */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl">
        <FeatureCard
          title="Translate"
          description="Real-time text translation with audio support. Contribute your own voice clips to enhance translations."
          link="/translate"
        />
        <FeatureCard
          title="Editor"
          description="Type in multiple scripts. Supports transliteration from English to native scripts."
          link="/editor"
        />
        <FeatureCard
          title="Learn"
          description="Engage in 500+ curated conversations. Add friends to personalize dialogues."
          link="/learn"
        />
      </div>

      {/* Navigation Links */}
      <footer className="mt-10 text-sm text-gray-500">
        {/* <div className="flex flex-wrap gap-4 justify-center">
          <FooterLink to="/contribute" label="Contribute" />
          <FooterLink to="/feedback" label="Feedback" />
          <FooterLink to="/about" label="About" />
          <FooterLink to="/help" label="Help" />
        </div> */}
        <p className="mt-4">© {new Date().getFullYear()} GTongue. All rights reserved.</p>
      </footer>
    </main>
  );
}

function FeatureCard({ title, description, link }) {
  return (
    <Card className="rounded-2xl shadow-md hover:shadow-lg transition-shadow">
      <CardContent className="p-6 flex flex-col items-start">
        <h2 className="text-2xl font-semibold mb-2">{title}</h2>
        <p className="text-sm text-gray-600 mb-4">{description}</p>
        <Button asChild className="mt-auto">
          <Link to={link}>Explore {title}</Link>
        </Button>
      </CardContent>
    </Card>
  );
}

function FooterLink({ to, label }) {
  return (
    <Link to={to} className="hover:underline">
      {label}
    </Link>
  );
}
