import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./Contexts/AuthContext";
import { LoadingProvider } from "./Contexts/LoadingContext";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>

    <AuthProvider>
      <LoadingProvider>
        <BrowserRouter
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true, // Add this flag
          }}>
          <App />
        </BrowserRouter>
      </LoadingProvider>
    </AuthProvider>
  </React.StrictMode>
);
