import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Globe, Edit, Keyboard, LogIn, HelpCircle, BookOpen, MessageCircle, Info, X, User, Shield } from "lucide-react";
import { AuthContext } from "../../Contexts/AuthContext";
import { toast } from "react-toastify";
import api from "../../Utils/Api.js";
import { useLoading } from "../../Contexts/LoadingContext";
// import "./Navbar.css";

function Navbar({ children }) {
  const { isLoggedIn, logout, isAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  const { setIsLoading } = useLoading();

  // State for feedback modal
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    message: "", // Change from content to message
  });

  const toggleFeedbackModal = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!feedbackData.message) {
      toast.error("Please enter a feedback message!");
      return;
    }

    const feedbackWithUrl = {
      message: feedbackData.message,
      pageUrl: window.location.pathname  // Send only the relative path
    };

    try {
      setIsLoading(true);
      const response = await api.post("api/feedback/add", feedbackWithUrl);

      if (response.status === 201) {
        toast.success("Feedback submitted successfully!");
        setFeedbackData({ message: "" });
        toggleFeedbackModal();
      } else {
        toast.error(response.error || "Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("An error occurred. Please try again.");
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex">

      {/* Desktop navbar */}
      <nav className="hidden md:flex flex-col fixed left-0 top-0 h-screen bg-white shadow-lg p-4 z-50 w-55">
        <div className="flex flex-col h-full">
          {/* Branding (Logo) */}
          <span onClick={() => navigate("/")} className="logo flex items-center space-x-2 font-bold text-xl mb-6 cursor-pointer">
            <img src="/GTongue_logo.png" alt="App Logo" className="w-12 h-12 object-contain" />
            <span className="relative -top-1">GTongue</span>
          </span>

          {/* Navigation Links */}
          <ul className="nav-links flex flex-col space-y-4 flex-grow">
            <li
              className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
              onClick={() => navigate("/Translate")}
            >
              <Globe size={18} />
              <span>Translate</span>
            </li>
            <li
              className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
              onClick={() => navigate("/Contribute")}
            >
              <Edit size={18} />
              <span>Contribute</span>
            </li>
            <li
              className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
              onClick={() => navigate("/editor")}
            >
              <Keyboard size={18} />
              <span>Editor</span>
            </li>
            {isLoggedIn && (
              <li
                className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                onClick={() => navigate("/Learn")}
              >
                <BookOpen size={18} />
                <span>Learn</span>
              </li>
            )}
            <li
              className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
              onClick={() => navigate("/about")}
            >
              <Info size={18} />
              <span>About Us</span>
            </li>
            {/* Additional links */}
            {isLoggedIn ? (
              <>
                <li
                  className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                  onClick={toggleFeedbackModal}
                >
                  <MessageCircle size={18} />
                  <span>Feedback</span>
                </li>
                <li
                  className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  <User size={18} />
                  <span>Profile</span>
                </li>
                <li
                  className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                  onClick={logout}
                >
                  <LogIn size={18} />
                  <span>Logout</span>
                </li>
                {isAdmin && (
                  <li
                    className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                    onClick={() => navigate("/admin")}
                  >
                    <Shield size={18} />
                    <span>Admin</span>
                  </li>
                )}
              </>
            ) : (
              <li
                className="flex items-center space-x-2 hover:text-blue-500 cursor-pointer"
                onClick={() => navigate("/auth")}
              >
                <LogIn size={18} />
                <span>Login/Register</span>
              </li>
            )}
          </ul>

          {/* Beta Button */}
          <button className="beta-btn bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
            Beta Version
          </button>
        </div>
      </nav>

      {/* Main content */}
      <main className="md:ml-56 md:mr-12 flex-grow min-h-screen pb-16 md:pb-0">
        {children}
      </main>

      {/* Mobile navbar (bottom) */}
      <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-lg z-50">
        <ul className="flex justify-around items-center p-3">
          <li className="flex flex-col items-center text-xs" onClick={() => navigate('/Translate')}>
            <Globe size={24} />
            <span className="mt-1">Translate</span>
          </li>
          <li className="flex flex-col items-center text-xs" onClick={() => navigate('/Contribute')}>
            <Edit size={24} />
            <span className="mt-1">Contribute</span>
          </li>
          <li className="flex flex-col items-center text-xs">
            <Keyboard size={24} onClick={() => { navigate('/editor') }} />
            <span className="mt-1">Editor</span>
          </li>
          {isLoggedIn && (
            <li className="flex flex-col items-center text-xs" onClick={() => navigate('/Learn')}>
              <BookOpen size={24} />
              <span className="mt-1">Learn</span>
            </li>
          )}
          <li className="flex flex-col items-center text-xs">
            <Info size={24} onClick={() => { navigate('/About') }} />
            <span className="mt-1">About Us</span>
          </li>
          {isLoggedIn ? (
            <>
              <li className="flex flex-col items-center text-xs" onClick={toggleFeedbackModal}>
                <MessageCircle size={24} />
                <span className="mt-1">Feedback</span>
              </li>
              <li className="flex flex-col items-center text-xs" onClick={() => navigate('/profile')}>
                <User size={24} />
                <span className="mt-1">Profile</span>
              </li>
              <li className="flex flex-col items-center text-xs" onClick={logout}>
                <LogIn size={24} />
                <span className="mt-1">Logout</span>
              </li>
              {isAdmin && (
                <li
                  className="flex flex-col items-center text-xs" onClick={() => navigate("/admin")} >
                  <Shield size={24} />
                  <span>Admin</span>
                </li>
              )}
            </>
          ) : (
            <li className="flex flex-col items-center text-xs" onClick={() => navigate('/auth')}>
              <LogIn size={24} />
              <span className="mt-1">Login/Register</span>
            </li>
          )}
          {/* <li className="flex flex-col items-center text-xs">
            <HelpCircle size={24} />
            <span className="mt-1">Help</span>
          </li> */}
        </ul>
      </nav>
      {/* Mobile navbar end */}


      {/* Feedback Modal */}
      {isFeedbackOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg popup-modal w-11/12 md:w-1/3">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Feedback</h2>
              <button onClick={toggleFeedbackModal}>
                <X size={20} />
              </button>
            </div>

            <textarea
              name="message"
              className="w-full border p-2 mt-2 rounded-md"
              rows="4"
              placeholder="Write your feedback..."
              value={feedbackData.message}
              onChange={handleChange}
              maxLength="1000"
            ></textarea>
            <div className="text-right text-sm text-gray-500">
              Maximum 1000 characters
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-md mr-2"
                onClick={toggleFeedbackModal}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default Navbar;