import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Api from '../../Utils/Api';

const MonthlyActivityChart = () => {
  const [monthlyActivity, setMonthlyActivity] = useState([]);

  useEffect(() => {
    const fetchMonthlyActivity = async () => {
      try {
        const res = await Api.get('/api/admin/monthlyActivity');
        setMonthlyActivity(res.data.monthlyActivity);
      } catch (error) {
        console.error('Error fetching monthly activity data:', error);
      }
    };

    fetchMonthlyActivity();
  }, []);

  const chartData = {
    labels: monthlyActivity.map((item) => item._id),
    datasets: [
      {
        label: 'Translations per Month',
        data: monthlyActivity.map((item) => item.count),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mt-6">
      <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">Monthly Activity</h3>
      {monthlyActivity.length > 0 ? (
        <div className="flex justify-center">
          <div className="w-full md:w-2/3 lg:w-1/2">
            <Bar data={chartData} />
          </div>
        </div>
      ) : (
        <p className="text-gray-600 text-center">No data available</p>
      )}
    </div>
  );
};

export default MonthlyActivityChart;