import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Mic, StopCircle, PlayCircle, Loader } from 'lucide-react';
import { toast } from 'react-toastify';
import FullScreenDropdown from '../../components/FullScreenDropdown/FullScreenDropdown';
import transliterationEnableLanguages from '../../Common/transliteration_enabled_languages.json';
import api from '../../Utils/Api';

function ContributionPage() {
  const [fromLan, setFromLan] = useState('');
  const [toLan, setToLan] = useState('');
  const [fromText, setFromText] = useState('');
  const [toText, setToText] = useState('');
  const [languages, setLanguages] = useState([]);
  const [fromSentenceArray, setFromSentenceArray] = useState([]);
  const [toSentenceArray, setToSentenceArray] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  // const [recognition, setRecognition] = useState(null);
  const [transliterationReady, setTransliterationReady] = useState(false);
  // const [recordingIndex, setRecordingIndex] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [activeRecording, setActiveRecording] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const lanControl = useRef(null);
  const fromTextRefs = useRef([]);
  const toTextRefs = useRef([]);
  const transliteratedRefs = useRef({ from: new Set(), to: new Set() });
  const scheduleUpdateTransliteration = useRef(false);

  const loadGoogleApi = () => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/jsapi";
    script.async = true;
    script.onload = () => {
      if (window.google && window.google.load) {
        window.google.load("elements", "1", {
          packages: "transliteration",
          callback: initializeTransliteration,
        });
      }
    };
    document.body.appendChild(script);
  };

  const initializeTransliteration = () => {
    if (!window.google?.elements?.transliteration) {
      setTimeout(initializeTransliteration, 500);
      return;
    }

    if (lanControl.current) {
      try {
        lanControl.current.fromControl.dispose();
        lanControl.current.toControl.dispose();
      }
      catch (error) {
        console.error("Error disposing transliteration controls:", error);
      }
      lanControl.current = null;
    }

    const fromControl = new window.google.elements.transliteration.TransliterationControl({
      sourceLanguage: "en",
      destinationLanguage: ["hi"],
      shortcutKey: "ctrl+g",
      transliterationEnabled: false,
    });

    const toControl = new window.google.elements.transliteration.TransliterationControl({
      sourceLanguage: "en",
      destinationLanguage: ["hi"],
      shortcutKey: "ctrl+g",
      transliterationEnabled: false,
    });

    lanControl.current = { fromControl, toControl };
    setTransliterationReady(true);
  };

  const isLanTransliterable = (language) => {
    if (!language || language === 'English') return false;
    const code = transliterationEnableLanguages.find(lan => lan.language === language)?.code;
    return code ?? false;
  };

  const updateTransliteration = useCallback(() => {
    if (!lanControl.current || !transliterationReady) return;

    const fromLanCode = isLanTransliterable(fromLan?.display_name);
    const toLanCode = isLanTransliterable(toLan?.display_name);

    lanControl.current.fromControl.disableTransliteration();
    lanControl.current.toControl.disableTransliteration();

    if (fromLanCode) {
      lanControl.current.fromControl.setLanguagePair("en", fromLanCode);
      lanControl.current.fromControl.enableTransliteration();
    }

    if (toLanCode) {
      lanControl.current.toControl.setLanguagePair("en", toLanCode);
      lanControl.current.toControl.enableTransliteration();
    }

    const processFields = (fields, controlType) => {
      fields.forEach((el) => {
        if (el && el.id && !transliteratedRefs.current[controlType].has(el.id)) {
          lanControl.current[`${controlType}Control`].makeTransliteratable([el]);
          transliteratedRefs.current[controlType].add(el.id);
        }
      });
    };

    processFields(fromTextRefs.current, 'from');
    processFields(toTextRefs.current, 'to');
  }, [fromLan, toLan, transliterationReady]);

  useEffect(() => {
    if (transliterationReady) {
      updateTransliteration();
    }
  }, [transliterationReady, fromLan, toLan, updateTransliteration]);

  useEffect(() => {
    getLanguageList();
    if (!window.google?.elements) {
      loadGoogleApi();
    } else {
      initializeTransliteration();
    }
  }, []);

  useEffect(() => {
    return () => {
      lanControl.current?.fromControl?.dispose();
      lanControl.current?.toControl?.dispose();
    };
  }, []);

  useEffect(() => {
    if (transliterationReady) updateTransliteration();
  }, [fromLan, toLan, transliterationReady, updateTransliteration]);

  useEffect(() => {
    if (scheduleUpdateTransliteration.current) {
      updateTransliteration();
      scheduleUpdateTransliteration.current = false;
    }
  });

  const getLanguageList = async () => {
    try {
      const response = await api.get('api/language/getList');
      setLanguages(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const splitSentence = async () => {
    setFromSentenceArray([]);
    setToSentenceArray([]);
    setIsLoading(true);
    try {
      const response = await api.post('api/contribute/submit-for-review', {
        fromLan: fromLan.language,
        toLan: toLan.language,
        fromText,
        toText,
      });

      if (response.data.errors?.length > 0) {
        toast.error(response.data.errors.join(', '));
        return;
    }

      fromTextRefs.current = [];
      toTextRefs.current = [];
      transliteratedRefs.current = { from: new Set(), to: new Set() };

      setFromSentenceArray(response.data.fromSentenceArray);
      setToSentenceArray(response.data.toSentenceArray);

      setTimeout(() => updateTransliteration(), 100);
    } catch (error) {
      toast.error(error.response?.data?.message || 'An unexpected error occurred.');
      console.error('Error on submit for review: ',error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveReviewedData = async () => {
    const formData = new FormData();
    formData.append('fromLan', fromLan.language);
    formData.append('toLan', toLan.language);

    fromSentenceArray.forEach((sentence, index) => {
      formData.append(`fromText[${index}]`, sentence.text);
      if (sentence.audio) {
        formData.append(`fromAudio[${index}]`, sentence.audio, `fromAudio_${index}.wav`);
      }
    });

    toSentenceArray.forEach((sentence, index) => {
      formData.append(`toText[${index}]`, sentence.text);
      if (sentence.audio) {
        formData.append(`toAudio[${index}]`, sentence.audio, `toAudio_${index}.wav`);
      }
    });
    setIsLoading(true);
    try {
      const response = await api.post('api/contribute/submit-after-review', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.success) {
        toast.success('Translation data saved successfully');
      } else {
        toast.error('Failed to save translation data');
      }
    } catch (error) {
      toast.error(error.response?.data?.errors?.join(' ') || 'An unexpected error occurred.');
      console.error('Error on submit after review: ',error);
    } finally {
      setIsLoading(false);
    }
  };

  const swapLanguages = () => {
    setFromLan(toLan);
    setToLan(fromLan);
    setFromText(toText);
    setToText(fromText);
    setFromSentenceArray(toSentenceArray);
    setToSentenceArray(fromSentenceArray);

    transliteratedRefs.current = { from: new Set(), to: new Set() };
    fromTextRefs.current = [];
    toTextRefs.current = [];

    lanControl.current?.fromControl?.dispose();
    lanControl.current?.toControl?.dispose();
    lanControl.current = null;

    // Reset ready flag to trigger useEffect for re-initialization
    setTransliterationReady(false);

    // Re-initialize the Transliteration after cleanup
    setTimeout(() => { initializeTransliteration(); }, 300);
  };

  const scrollToForm = () => {
    const element = document.getElementById('translation-form');
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const updateFromSentence = (index, text, audio = null) => {
    setFromSentenceArray((prev) => {
      const newArray = [...prev];
      newArray[index] = { ...newArray[index], text };
      if (audio !== null) newArray[index].audio = audio;
      return newArray;
    });
  };

  const updateToSentence = (index, text, audio = null) => {
    setToSentenceArray((prev) => {
      const newArray = [...prev];
      newArray[index] = { ...newArray[index], text };
      if (audio !== null) newArray[index].audio = audio;
      return newArray;
    });
  };

  //voice features
  const startRecording = (index, type) => {
    setIsRecording(true);
    setActiveRecording({ index, type });
    // const isSpeechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;

    // if (isSpeechRecognitionSupported) {
    //   const newRecognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    //   newRecognition.lang = 'en-US';
    //   newRecognition.continuous = true;
    //   newRecognition.onresult = (event) => {
    //     const transcript = Array.from(event.results).map(result => result[0].transcript).join(' ');
    //     type === 'F' ? updateFromSentence(index, transcript) : updateToSentence(index, transcript);
    //   };
    //   newRecognition.start();
    //   setRecognition(newRecognition);
    // } else {
    //   console.error("Speech recognition not supported in this browser.");
    // }
      

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const newMediaRecorder = new MediaRecorder(stream);
      const tempChunks = [];
      newMediaRecorder.ondataavailable = (e) => e.data.size > 0 && tempChunks.push(e.data);
      newMediaRecorder.onstop = () => {
        if (tempChunks.length) {
          const audioBlob = new Blob(tempChunks, { type: 'audio/wav' });
          type === 'F'
            ? updateFromSentence(index, fromSentenceArray[index]?.text || '', audioBlob)
            : updateToSentence(index, toSentenceArray[index]?.text || '', audioBlob);
        }
      };
      newMediaRecorder.start();
      setMediaRecorder(newMediaRecorder);
    });
  };

  const stopRecording = () => {
    setIsRecording(false);
    setActiveRecording(null);
    if (mediaRecorder) mediaRecorder.stop();
    // if (recognition) recognition.stop();
    setMediaRecorder(null);
    // setRecognition(null);
  };

  const playRecording = (index, type) => {
    const audioBlob = type === 'F' ? fromSentenceArray[index].audio : toSentenceArray[index].audio;
    if (!audioBlob) return console.error("No audio data available");
    new Audio(URL.createObjectURL(audioBlob)).play();
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto py-8">
        <h2 className="text-2xl font-bold mb-6 text-center">Contribute to Translation</h2>
        <div className="bg-white p-8 rounded-lg shadow-md">
          <div id='translation-form' className="flex flex-col md:flex-row items-center justify-between mb-4">
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <FullScreenDropdown
                label="From"
                dataSource={languages}
                fields={{ id: "language", text: "display_name" }}
                onSelected={(lang) => setFromLan(lang)}
                selectedValue={fromLan}
              />
              <textarea
                id="from_text"
                ref={el => {
                  fromTextRefs.current[0] = el;
                  if (el && !transliteratedRefs.current.from.has(el.id)) {
                    scheduleUpdateTransliteration.current = true;
                  }
                }}
                className="w-full h-32 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 fromLan"
                value={fromText}
                onChange={(e) => setFromText(e.target.value)}
                placeholder="Type the source text here..."
              ></textarea>
            </div>

            <button
              className="text-2xl mx-4 transform rotate-90 md:rotate-0"
              onClick={swapLanguages}
            >
              &#8646;
            </button>

            <div className="w-full md:w-1/2">
              <FullScreenDropdown
                label="To"
                dataSource={languages}
                fields={{ id: "language", text: "display_name" }}
                onSelected={(lang) => setToLan(lang)}
                selectedValue={toLan}
              />
              <textarea
                id="to_text"
                ref={el => {
                  toTextRefs.current[0] = el;
                  if (el && !transliteratedRefs.current.to.has(el.id)) {
                    scheduleUpdateTransliteration.current = true;
                  }
                }}
                className="w-full h-32 p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 toLan"
                value={toText}
                onChange={(e) => setToText(e.target.value)}
                placeholder="Type the target text here..."
              ></textarea>
            </div>
          </div>

          <button
            type="submit"
            className={`w-full md:w-auto bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-200 ${isRecording ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={splitSentence}
            disabled={isLoading || isRecording || !fromLan?.language || !toLan?.language || !fromText || !toText}
            title='Click to split the sentences for review'
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <Loader className="w-5 h-5 animate-spin" />
                <span>Preparing Review...</span>
              </div>) 
              : ('Review')}
          </button>

          {fromSentenceArray.length > 0 && (
            <div className="mt-8">
              <h3 className="text-xl font-bold mb-4 text-center">Review Translations</h3>
              {fromSentenceArray.map((value, key) => (
                <div key={key} className="mb-4 p-4 bg-gray-50 rounded-lg shadow-md">
                  <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700">Source Sentence</label>
                    <div className="flex items-center">
                      <textarea
                        rows="1"
                        id={`from-text-${key + 1}`}
                        ref={el => {
                          fromTextRefs.current[key + 1] = el;
                          if (el && !transliteratedRefs.current.from.has(el.id)) {
                            scheduleUpdateTransliteration.current = true;
                          }
                        }}
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 mb-2 fromLan_review"
                        name="from-text[]"
                        value={fromSentenceArray[key].text}
                        onChange={(e) => updateFromSentence(key, e.target.value)}
                      ></textarea>
                      <div className="flex space-x-2 ml-2">
                        {/* Disable other buttons if recording is active elsewhere */}
                        <button
                          type="button"
                          disabled={activeRecording && !(activeRecording.index === key && activeRecording.type === 'F')}
                          onClick={() => startRecording(key, 'F')}
                          className={`p-2 rounded-full ${activeRecording ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'} shadow-lg transition hover:scale-110`}
                        >
                          <Mic className="w-5 h-5 text-white" />
                        </button>

                        {activeRecording?.index === key && activeRecording?.type === 'F' && (
                          <button
                            type="button"
                            onClick={stopRecording}
                            className="p-2 rounded-full bg-yellow-500 hover:bg-yellow-600 shadow-lg transition animate-bounce"
                          >
                            <StopCircle className="w-5 h-5 text-white" />
                          </button>
                        )}

                        <button
                          type="button"
                          disabled={activeRecording}
                          onClick={() => playRecording(key, 'F')}
                          className={`p-2 rounded-full ${activeRecording ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'} shadow-lg transition hover:scale-110`}
                        >
                          <PlayCircle className="w-5 h-5 text-white" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Target Sentence</label>
                    <div className="flex items-center">
                      <textarea
                        rows="1"
                        id={`to-text-${key + 1}`}
                        ref={el => {
                          toTextRefs.current[key + 1] = el;
                          if (el && !transliteratedRefs.current.to.has(el.id)) {
                            scheduleUpdateTransliteration.current = true;
                          }
                        }}
                        className="w-full p-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 mb-2 toLan_review"
                        name="to-text[]"
                        value={toSentenceArray[key].text}
                        onChange={(e) => updateToSentence(key, e.target.value)}
                      ></textarea>
                      <div className="flex space-x-2 ml-2">
                        {/* Disable other buttons if recording is active elsewhere */}
                        <button
                          type="button"
                          disabled={activeRecording && !(activeRecording.index === key && activeRecording.type === 'T')}
                          onClick={() => startRecording(key, 'T')}
                          className={`p-2 rounded-full ${activeRecording ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'} shadow-lg transition hover:scale-110`}
                        >
                          <Mic className="w-5 h-5 text-white" />
                        </button>

                        {activeRecording?.index === key && activeRecording?.type === 'T' && (
                          <button
                            type="button"
                            onClick={stopRecording}
                            className="p-2 rounded-full bg-yellow-500 hover:bg-yellow-600 shadow-lg transition animate-bounce"
                          >
                            <StopCircle className="w-5 h-5 text-white" />
                          </button>
                        )}

                        <button
                          type="button"
                          disabled={activeRecording}
                          onClick={() => playRecording(key, 'T')}
                          className={`p-2 rounded-full ${activeRecording ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'} shadow-lg transition hover:scale-110`}
                        >
                          <PlayCircle className="w-5 h-5 text-white" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className={`w-full md:w-auto bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 shadow-lg focus:ring-2 focus:ring-green-400 transition duration-200 ${isRecording ? 'opacity-50 cursor-not-allowed' : ''}`}
                name="submit_after_review"
                onClick={saveReviewedData}
                disabled={isLoading || isRecording || !fromLan?.language || !toLan?.language || fromSentenceArray.length === 0 || toSentenceArray.length === 0}
                title='Click to save the reviewed translations'
              >
                {isLoading ? (
                 <div className="flex items-center justify-center space-x-2">
                 <Loader className="w-5 h-5 animate-spin" />
                 <span>Saving...</span>
               </div> ) 
               : ( 'Submit' )}
              </button>
            </div>
          )}

          <a href="#" onClick={(e) => { e.preventDefault(); scrollToForm(); }} className="block text-center mt-4 text-blue-500 hover:text-blue-700">
            Jump to the input form
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContributionPage;