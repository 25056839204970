import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Contexts/AuthContext';
import api from '../../Utils/Api';
import { toast } from 'react-toastify';

const Login = ({returnTo}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/auth/login',
        { email, password }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      // const data = await response.json();
      if (response.data) {
        login(response.data);
        toast.success('Login successful!');
        navigate(returnTo ? `${returnTo}` : "/", { replace: true });
      } else {
        toast(response.message, { type: 'error' });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          toast.error('Invalid credentials');
        } else {
          toast.error('Server error');
        }
      } else {
        toast.error('Network error');
      }
      console.error('Error logging in:', error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-200"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;