import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Dialogue.css";
import { ArrowLeft, ArrowUp, ArrowDown } from "lucide-react"; // Import Lucide React icons
import { AuthContext } from "../../Contexts/AuthContext";
import api from "../../Utils/Api";
import Modal from "../../components/Modal"; // Import the Modal component
import AboutPageContent from "./Aboutpage"; // Import the AboutPageContent component

const DialoguePage = () => {
  const { user } = useContext(AuthContext); // Get user context
  const navigate = useNavigate(); // Hook for navigation

  const { language } = useParams(); // Get language parameter from URL
  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  const [originalDialogue, setOriginalDialogue] = useState(""); // State to store the original dialogue
  const [modifiedDialogue, setModifiedDialogue] = useState([]); // State to store the modified dialogue
  const [learners, setLearners] = useState([]); // State to store learners
  const [loading, setLoading] = useState(true); // State to control loading state
  const [error, setError] = useState(""); // State to store error messages
  const [currentIndex, setCurrentIndex] = useState(0); // State to store the current conversation index
  const [currentLine, setCurrentLine] = useState(-1); // State to store the current line index

  const highlightedLineRef = useRef(null); // Ref to the highlighted line

  useEffect(() => {
    // Fetch learners from the API
    const fetchLearners = async () => {
      const storedLearners =
        JSON.parse(localStorage.getItem("selectedLearners")) || [];
      if (storedLearners.length === 0) {
        setLearners([]);
        return;
      }

      if (!user.id) return;

      try {
        const response = await api.get(`/api/Learn/users/learners`);
        const data = await response.data;
        const filteredLearners = data.filter((learner) =>
          storedLearners.includes(learner._id)
        );
        setLearners(filteredLearners.map((l) => l.feature));
      } catch (error) {
        console.error("Error fetching learners:", error);
      }
    };

    // Fetch dialogue from the text file
    const fetchDialogue = async () => {
      try {
        const scriptPath = `../../languageScripts/${language}.txt`;
        const response = await fetch(scriptPath);
        if (!response.ok) {
          throw new Error("Failed to load text file");
        }

        const contentType = response.headers.get("Content-Type");
        if (!contentType || !contentType.includes("text/plain")) {
          throw new Error("Invalid script format");
        }

        const text = await response.text();
        const lines = text.split("\n");
        if (lines.length > 1) {
          lines.splice(1, 1);
        }
        setOriginalDialogue(lines.join("\n"));
      } catch (error) {
        console.error("Error loading text file:", error);
        setError("The script was not available. Please try another language.");
        setTimeout(() => navigate("/Learn"), 10000);
      } finally {
        setLoading(false);
      }
    };

    fetchLearners().then(fetchDialogue);
  }, []);

  useEffect(() => {
    // Modify dialogue with learner names
    if (learners.length > 0 && originalDialogue) {
      const modifiedText = replaceAllBeforeColonWithLearners(
        originalDialogue,
        learners
      );
      const conversations = splitIntoConversations(modifiedText);
      setModifiedDialogue(conversations);
    }
  }, [originalDialogue]);

  useEffect(() => {
    // Set the current line to the first learner line
    if (modifiedDialogue[currentIndex]) {
      const lines = modifiedDialogue[currentIndex].split("\n");
      const firstLearnerLine = lines.findIndex((line) =>
        line.includes("learner-name")
      );
      setCurrentLine(firstLearnerLine >= 0 ? firstLearnerLine : 0);
    }
  }, [currentIndex, modifiedDialogue]);

  useEffect(() => {
    // Scroll to the highlighted line
    if (highlightedLineRef.current && currentLine !== -1) {
      highlightedLineRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentLine, currentIndex]);

  // Replace all text before the colon with learner names
  const replaceAllBeforeColonWithLearners = (text, learners) => {
    if (learners.length === 0) return text;

    let shuffledNames = shuffleArray([...learners]);
    let usedNames = new Set();
    let recentlyUsedNames = [];
    const minDistance = 3;
    const maxAttempts = shuffledNames.length * 2;

    return text.replace(
      /^(.+?)\s*:(.*)$/gm,
      (match, beforeColon, afterColon) => {
        let assignedName;
        let attempts = 0;

        while (attempts < maxAttempts) {
          assignedName =
            shuffledNames[Math.floor(Math.random() * shuffledNames.length)];
          attempts++;

          if (
            !usedNames.has(assignedName) &&
            !recentlyUsedNames.includes(assignedName)
          ) {
            break;
          }

          if (attempts >= maxAttempts) {
            console.warn(
              "Max attempts reached, assigning a random name to avoid infinite loop."
            );
            assignedName =
              shuffledNames[Math.floor(Math.random() * shuffledNames.length)];
            break;
          }
        }

        usedNames.add(assignedName);

        recentlyUsedNames.push(assignedName);
        if (recentlyUsedNames.length > minDistance) {
          recentlyUsedNames.shift();
        }

        return `<span class="learner-name"><strong>${assignedName}</strong></span>:<span class="conversation-text">${afterColon}</span>`;
      }
    );
  };

  // Shuffle an array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Split text into conversations
  const splitIntoConversations = (text) => {
    const splitPattern = /\n\s*\n/;
    const conversations = text
      .split(splitPattern)
      .map((conv) => conv.trim())
      .filter((conv) => conv.length > 0);

    console.log("Total Conversations Found:", conversations.length);
    return conversations;
  };

  // Handle next conversation
  const handleNext = () => {
    if (currentIndex < modifiedDialogue.length - 1) {
      const nextIndex = currentIndex + 1;
      const lines = modifiedDialogue[nextIndex]?.split("\n") || [];
      const firstLearnerLine = lines.findIndex((line) =>
        line.includes("learner-name")
      );
      setCurrentIndex(nextIndex);
      setCurrentLine(firstLearnerLine >= 0 ? firstLearnerLine : 0);
    }
  };

  // Handle previous conversation
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentLine(0);
    }
  };

  // Handle conversation selection
  const handleConversationSelect = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    setCurrentIndex(selectedIndex);
    setCurrentLine(0);
  };

  // Handle next line
  const handleNextLine = () => {
    const lines = modifiedDialogue[currentIndex]?.split("\n") || [];
    if (currentLine < lines.length - 1) {
      setCurrentLine((prevLine) => (prevLine === -1 ? 0 : prevLine + 1));
    }
  };

  // Handle previous line
  const handlePreviousLine = () => {
    const lines = modifiedDialogue[currentIndex]?.split("\n") || [];
    if (currentLine > 0) {
      setCurrentLine((prevLine) => prevLine - 1);
    }
  };

  // Render highlighted dialogue
  const renderHighlightedDialogue = () => {
    const lines = modifiedDialogue[currentIndex]?.split("\n") || [];

    return lines.map((line, index) => (
      <div
        key={index}
        ref={index === currentLine ? highlightedLineRef : null}
        className={index === currentLine ? "highlighted-line" : ""}
        style={
          index === 0
            ? {
                textAlign: "center",
                color: "red",
                fontSize: "bold",
                marginBottom: "10px",
                marginTop: "-20px",
              }
            : {}
        }
        dangerouslySetInnerHTML={{ __html: line }}
      />
    ));
  };

  return (
    <div className="dialogue-container">
      {/* Go Back Button */}
      <button
        id="GoBack"
        onClick={() => navigate("/Learn")}
        title="Go Back"
        className="go-back-button"
      >
        <ArrowLeft />
      </button>
      {/* About This Page Button */}
      <button
        id="AboutPage"
        onClick={() => setShowModal(true)}
        title="About This Page"
        className="about-page-button"
      >
        About This Page
      </button>
      <div className="content">
        <h1 className="page-title">
          {`Learn ${language} Anytime, Anywhere, at Your Own Pace.`}
        </h1>

        {/* Loading and Error Messages */}
        {loading && <p className="loading-text">Loading...</p>}
        {error && <div className="popup-message">{error}</div>}

        {/* Conversation Selector and Dialogue Display */}
        {!loading && !error && modifiedDialogue.length > 0 && (
          <>
            {/* Dropdown to select conversation */}
            <select
              className="conversation-selector"
              value={currentIndex}
              onChange={handleConversationSelect}
            >
              {modifiedDialogue.map((_, index) => (
                <option key={index} value={index} title="Select Conversation">
                  Conversation {index + 1}
                </option>
              ))}
            </select>

            {/* Display the current conversation */}
            <div className="conversation-block">
              <pre className="script-text">{renderHighlightedDialogue()}</pre>
            </div>
          </>
        )}
        <div className="btn-cons">
          {/* Previous Line Button */}
          <button
            className="btn-up-dw fixed-btn"
            id="BackLine"
            onClick={handlePreviousLine}
            disabled={currentLine <= 0}
            title="Back Line"
          >
            <ArrowUp /> {/* Up arrow icon */}
          </button>
          {/* Next Line Button */}
          <button
            className="btn-up-dw fixed-btn"
            id="NextLine"
            onClick={handleNextLine}
            disabled={
              !modifiedDialogue[currentIndex] ||
              currentLine >=
                modifiedDialogue[currentIndex].split("\n").length - 1
            }
            title="Next Line"
          >
            <ArrowDown /> {/* Down arrow icon */}
          </button>
        </div>
        {/* Navigation Buttons */}
        <div className="button-con">
          {/* Previous Conversation Button */}
          <button
            id="pb"
            className="fixed-btn"
            onClick={handlePrevious}
            disabled={currentIndex === 0}
            title="Previous Conversation"
          >
            Previous
          </button>

          {/* Next Conversation Button */}
          <button
            id="nb"
            className="fixed-btn"
            onClick={handleNext}
            disabled={currentIndex >= modifiedDialogue.length - 1}
            title="Next Conversation"
          >
            Next
          </button>
        </div>
      </div>
      {/* Modal for About This Page */}
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <AboutPageContent />
      </Modal>
    </div>
  );
};

export default DialoguePage;
