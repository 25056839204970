import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Api from '../Utils/Api.js';
import { toast } from 'react-toastify';
import { Trash2 } from 'lucide-react';
import { confirmAlert } from 'react-confirm-alert'; // Import the library
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS

const UserFeedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const feedbacksPerPage = 10; // Number of feedbacks per page

    // Fetch user's feedbacks whenever page or search changes
    useEffect(() => {
        const fetchFeedbacks = async () => {
            setIsLoading(true);
            try {
                const res = await Api.get(`/api/feedback?page=${currentPage}&limit=${feedbacksPerPage}&search=${search}`);
                setFeedbacks(res.data.feedbacks);
                setTotalRows(res.data.total);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
                toast.error('Failed to fetch feedbacks.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchFeedbacks();
    }, [currentPage, search]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const handleDelete = async (feedbackId) => {
        try {
            await Api.delete(`/api/feedback/${feedbackId}`);
            setFeedbacks((prev) => prev.filter((feedback) => feedback._id !== feedbackId));
            toast.success('Feedback deleted successfully.');
        } catch (error) {
            console.error('Error deleting feedback:', error);
            toast.error('Failed to delete feedback.');
        }
    };

    const confirmDelete = (feedbackId) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this feedback?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(feedbackId),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    // Define columns for the data table
    const columns = [
        {
            name: 'Message',
            selector: (row) => row.message,
            sortable: true,
        },
        {
            name: 'Page URL',
            selector: (row) => row.pageUrl,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Delete',
            cell: (row) => (
                <button
                    className="text-red-600 hover:text-red-800"
                    onClick={() => confirmDelete(row._id)} // Use the confirmation popup
                >
                    <Trash2 size={16} />
                </button>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">My Feedbacks</h2>

            {/* Search Input */}
            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search feedbacks"
                    value={search}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Data Table */}
            <DataTable
                columns={columns}
                data={feedbacks}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={feedbacksPerPage}
                onChangePage={handlePageChange}
                progressPending={isLoading}
                highlightOnHover
                responsive
                striped
            />
        </div>
    );
};

export default UserFeedbacks;