import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import api from '../Utils/Api';
import { toast } from 'react-toastify';
import { Trash2 } from 'lucide-react';

function LanguageList() {
    const [languages, setLanguages] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredLanguages, setFilteredLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchLanguages = async () => {
            setIsLoading(true);
            try {
                const res = await api.get('/api/language/getList');
                setLanguages(res.data);
                setFilteredLanguages(res.data); // Initialize filtered languages
            } catch (error) {
                console.error('Error fetching languages:', error);
                toast.error('Failed to fetch languages.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchLanguages();
    }, []);

    // Handle search input
    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearch(searchTerm);
        const filtered = languages.filter((language) =>
            language.display_name.toLowerCase().includes(searchTerm) ||
            language.lan_code.toLowerCase().includes(searchTerm) ||
            language.script.toLowerCase().includes(searchTerm)
        );
        setFilteredLanguages(filtered);
    };

    // Define columns for the data table
    const columns = [
        {
            name: 'Language Name',
            selector: (row) => row.display_name,
            sortable: true,
            minWidth: '150px', // Set a minimum width for responsiveness
        },
        {
            name: 'Language Code',
            selector: (row) => row.lan_code,
            sortable: true,
            minWidth: '100px', // Set a minimum width for responsiveness
        },
        {
            name: 'Script',
            selector: (row) => row.script,
            sortable: true,
            minWidth: '120px', // Set a minimum width for responsiveness
        },
        {
            name: 'Direction',
            selector: (row) => row.direction,
            sortable: true,
            minWidth: '100px', // Set a minimum width for responsiveness
        },
        {
            name: 'Description',
            selector: (row) => row.description,
            sortable: false,
            wrap: true, // Wrap long text for better responsiveness
            minWidth: '200px', // Set a minimum width for responsiveness
        },
        {
            name: 'Actions',
            cell: (row) => (
                <button
                    className="text-red-600 hover:text-red-800"
                    onClick={() => handleDelete(row.id)}
                >
                    <Trash2 size={16} />
                </button>
            ),
            minWidth: '100px', // Set a minimum width for responsiveness
        },
    ];

    // Handle delete action
    const handleDelete = async (languageId) => {
        if (window.confirm('Are you sure you want to delete this language?')) {
            try {
                await api.delete(`/api/language/${languageId}`);
                setLanguages((prev) => prev.filter((language) => language.id !== languageId));
                setFilteredLanguages((prev) => prev.filter((language) => language.id !== languageId));
                toast.success('Language deleted successfully.');
            } catch (error) {
                console.error('Error deleting language:', error);
                toast.error('Failed to delete language.');
            }
        }
    };

    return (
        <div className="mx-auto mt-4 p-4">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Languages</h2>

            {/* Search Input */}
            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search languages"
                    value={search}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Data Table */}
            <DataTable
                columns={columns}
                data={filteredLanguages}
                pagination
                highlightOnHover
                responsive
                striped
                progressPending={isLoading}
                customStyles={{
                    rows: {
                        style: {
                            minHeight: '48px', // Override the row height for better responsiveness
                        },
                    },
                    headCells: {
                        style: {
                            fontSize: '14px',
                            fontWeight: 'bold',
                            backgroundColor: '#f9fafb',
                            color: '#374151',
                        },
                    },
                    cells: {
                        style: {
                            fontSize: '14px',
                            color: '#4b5563',
                        },
                    },
                }}
            />
        </div>
    );
}

export default LanguageList;
