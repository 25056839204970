import React, { useEffect, useState } from 'react';
import api from '../../Utils/Api';
import FullScreenDropdown from '../../components/FullScreenDropdown/FullScreenDropdown';
import Transliterate from './Transliterate';
import transliterable_languages from "../../Common/transliteration_enabled_languages.json";

export const EditorPage = () => {
    const [languages, setLanguages] = useState([]);
    const [transliterableLanguages, setTransliterableLanguages] = useState(
      transliterable_languages
    );  
    const [selectedScript, setSelectedScript] = useState('');

    useEffect(() => {
        setTransliterableLanguages(transliterable_languages);
        getLanguageList();
    }, []);

    const getLanguageList = async () => {
        try {
            const response = await api.get('api/language/getList');
            setLanguages(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const setSelectedLanguage = (language) => {
        // Check if the selected language has an associated script in transliterableLanguages
        const matchedScript = transliterableLanguages.find(
            (transLang) => transLang.language === language.display_name
        );

        const defaultScript = matchedScript ? matchedScript.code : language.script[0] || "";

        setSelectedScript(defaultScript);
    };

    return (
        <div className="app-container">
            <div className="editor-heading">
                <h2 className="slogan">"Write in Any Language, Express in Every Script"</h2>
                <h3 className="slogan1">"Because communication has no alphabet."</h3>
            </div>
            <FullScreenDropdown placeholder="Select a language"
                label=""
                dataSource={languages}
                fields={{ id: "language", text: "display_name" }}
                onSelected={(selectedItem) => setSelectedLanguage(selectedItem) }
            />
            <Transliterate language_script={selectedScript} onTextChange={() => { }} />
        </div>
    );
};
