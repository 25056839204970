import React, { useContext, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../Contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const toastShown = useRef(false);

  if (!isLoggedIn) {
    if (!toastShown.current) {
      toast.warning("You need to be logged in to access this page.", {
        toastId: "auth-warning",
      });
      toastShown.current = true;
    }
    return <Navigate to="/auth" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export const AdminRoute = ({ children }) => {
  const { isLoggedIn, isAdmin } = useContext(AuthContext);
  const location = useLocation();
  const toastShown = useRef(false);

  if( isLoggedIn === null ) return; // Loading state, do not render anything yet

  if (!isLoggedIn) {
    if (!toastShown.current) {
      toast.warning("You need to be logged in to access this page.", {
        toastId: "auth-warning",
      });
      toastShown.current = true;
    }
    return <Navigate to="/auth" state={{ from: location.pathname }} replace />;
  }

  if (!isAdmin) {
    if (!toastShown.current) {
      toast.error("Admins only: Access denied", {
        toastId: "admin-access-denied",
      });
      toastShown.current = true;
    }
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;