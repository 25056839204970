import React, { useEffect, useState, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import Api from '../../Utils/Api';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import the library
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS
import { Trash2 } from 'lucide-react';

const Feedbacks = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const feedbacksPerPage = 10; // Number of feedbacks per page

    // Fetch feedbacks whenever page or search changes
    useEffect(() => {
        const fetchFeedbacks = async () => {
            setIsLoading(true);
            try {
                const res = await Api.get(`/api/feedback/all?page=${currentPage}&limit=${feedbacksPerPage}&search=${search}`);
                setFeedbacks(res.data.feedbacks);
                setTotalRows(res.data.total);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
                toast.error('Failed to fetch feedbacks.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchFeedbacks();
    }, [currentPage, search]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const handleDelete = useCallback(async (feedbackId) => {
        try {
            await Api.delete(`/api/feedback/${feedbackId}`);
            setFeedbacks((prev) => prev.filter((feedback) => feedback._id !== feedbackId));
            toast.success('Feedback deleted successfully.');
        } catch (error) {
            console.error('Error deleting feedback:', error);
            toast.error('Failed to delete feedback.');
        }
    }, []);

    const confirmDelete = (feedbackId) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this feedback?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(feedbackId),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    const handleStatusChange = async (feedbackId, newStatus) => {
        try {
            const res = await Api.patch(`/api/feedback/${feedbackId}/status`, { status: newStatus });
            setFeedbacks((prev) =>
                prev.map((feedback) =>
                    feedback._id === feedbackId ? { ...feedback, status: res.data.feedback.status } : feedback
                )
            );
            toast.success('Feedback status updated successfully.');
        } catch (error) {
            console.error('Error updating feedback status:', error);
            toast.error('Failed to update feedback status.');
        }
    };

    const confirmStatusChange = (feedbackId, newStatus) => {
        confirmAlert({
            title: 'Confirm Status Change',
            message: `Are you sure you want to change the status to "${newStatus}"?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleStatusChange(feedbackId, newStatus),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    // Define columns for the data table
    const columns = [
        {
            name: 'Feedback ID',
            selector: (row) => row._id,
            sortable: true,
            omit: true, // Hide this column
        },
        {
            name: 'User',
            selector: (row) => row.user.username,
            sortable: true,
        },
        {
            name: 'Page URL',
            selector: (row) => row.pageUrl,
            sortable: true,
        },
        {
            name: 'Message',
            selector: (row) => row.message,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <select
                    value={row.status}
                    onChange={(e) => confirmStatusChange(row._id, e.target.value)} // Use the confirmation popup
                    className="form-select form-select-sm"
                >
                    <option value="pending">Pending</option>
                    <option value="reviewed">Reviewed</option>
                    <option value="resolved">Resolved</option>
                </select>
            ),
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Delete',
            cell: (row) => (
                <button
                    aria-label="Delete Feedback"
                    className="btn-sm text-red-600"
                    onClick={() => confirmDelete(row._id)}
                >
                    <Trash2 size={16} />
                </button>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Feedback List</h2>

            {/* Search Input */}
            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search feedbacks"
                    value={search}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Data Table */}
            <DataTable
                columns={columns}
                data={feedbacks}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={feedbacksPerPage}
                onChangePage={handlePageChange}
                progressPending={isLoading}
                highlightOnHover
                responsive
                striped
            />
        </div>
    );
};

export default Feedbacks;