import React, { useEffect, useState, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import Api from '../../Utils/Api.js';
import { toast } from 'react-toastify';
import { Edit, Trash2, Key } from 'lucide-react';
import { confirmAlert } from 'react-confirm-alert'; // Import the library
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the CSS

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch users whenever page or search changes
    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            fetchUsers(currentPage, search);
        }, 500);

        return () => clearTimeout(delayDebounce);
    }, [currentPage, search]);

    const fetchUsers = async (page = 1, query = '') => {
        setIsLoading(true);
        try {
            const res = await Api.get(`/api/auth/users?page=${page}&limit=10&search=${query}`);
            setUsers(res.data.users);
            setTotalRows(res.data.total);
        } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Failed to fetch users.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = useCallback(async (userId) => {
        try {
            await Api.delete(`/api/auth/user/${userId}`);
            setUsers((prev) => prev.filter((user) => user._id !== userId));
            toast.success('User deleted successfully.');
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Failed to delete user.');
        }
    }, []);

    const confirmDelete = (userId) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this user?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleDelete(userId),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    const handleResetPassword = useCallback(async (userId) => {
        try {
            await Api.post(`/api/auth/reset-password`, { userId });
            toast.success('Password reset successfully. The user will receive an email.');
        } catch (error) {
            console.error('Error resetting password:', error);
            toast.error('Failed to reset password.');
        }
    }, []);

    const confirmResetPassword = (userId) => {
        confirmAlert({
            title: 'Confirm Reset Password',
            message: 'Are you sure you want to reset this user\'s password?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => handleResetPassword(userId),
                },
                {
                    label: 'No',
                },
            ],
        });
    };

    const handleEdit = useCallback((userId) => {
        toast.info(`Edit functionality for user ${userId} is not implemented yet.`);
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setCurrentPage(1); // Reset to first page
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            name: 'ID',
            selector: (row) => row._id,
            sortable: true,
        },
        {
            name: 'Username',
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Role',
            cell: (row) =>
                row.isAdmin ? (
                    <span className="badge bg-success">Admin</span>
                ) : (
                    <span className="badge bg-secondary">User</span>
                ),
            sortable: true,
        },
        {
            name: 'Reset Password',
            cell: (row) => (
                <button
                    aria-label="Reset Password"
                    className="btn btn-warning btn-sm d-flex align-items-center gap-1"
                    onClick={() => confirmResetPassword(row._id)} // Use the confirmation popup
                >
                    <Key size={17} />
                </button>
            ),
        },
        {
            name: 'Edit',
            cell: (row) => (
                <button
                    aria-label="Edit User"
                    className="btn btn-primary btn-sm d-flex align-items-center gap-1"
                    onClick={() => handleEdit(row._id)}
                >
                    <Edit size={16} />
                </button>
            ),
        },
        {
            name: 'Delete',
            cell: (row) => (
                <button
                    aria-label="Delete User"
                    className="text-red-600 btn-sm d-flex align-items-center gap-1"
                    onClick={() => confirmDelete(row._id)} // Use the confirmation popup
                >
                    <Trash2 size={16} />
                </button>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">User List</h2>

            {/* Search Input */}
            <div className="d-flex gap-2 mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search by username or email"
                    onChange={handleSearchChange}
                    aria-label="Search users"
                />
            </div>

            <DataTable
                columns={columns}
                data={users}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={10}
                onChangePage={handlePageChange}
                progressPending={isLoading}
                highlightOnHover
                responsive
                striped
            />
        </div>
    );
};

export default UserList;