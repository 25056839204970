import React from "react";

export const Button = React.forwardRef(({ className, children, asChild = false, ...props }, ref) => {
  const Component = asChild ? "span" : "button";
  return (
    <Component
      ref={ref}
      className={
        "inline-flex items-center justify-center rounded-md bg-blue-600 text-white px-4 py-2 text-sm font-medium hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        + (className ? ` ${className}` : "")
      }
      {...props}
    >
      {children}
    </Component>
  );
});

Button.displayName = "Button";