import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from '../../components/Auth/Login';
import Register from '../../components/Auth/Register';
import { toast } from 'react-toastify';


const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const location = useLocation();

  // Get the return URL from the state or default to the home page
  const from = location.state?.from?.pathname || "/";



  const toggleAuthMode = () => {
    setIsLogin(!isLogin);
  };

  const onRegisterSuccess = () => {
    setIsLogin(true);
    toast(<>Registered successfully! <br></br> Login to continue. </>, { type: 'success' });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {isLogin ? <Login returnTo={from} /> : <Register successCallback={onRegisterSuccess} />}
        <button
          onClick={toggleAuthMode}
          className="mt-4 w-full bg-gray-500 text-white py-2 rounded-md hover:bg-gray-600 transition duration-200"
        >
          {isLogin ? 'Switch to Register' : 'Switch to Login'}
        </button>
      </div>
    </div>
  );
};

export default AuthPage;