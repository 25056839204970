import React, { useEffect, useState } from 'react';
import Api from '../../Utils/Api';
import { toast } from 'react-toastify';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useLoading } from '../../Contexts/LoadingContext';

const TranslationStats = () => {
  const [totalTranslations, setTotalTranslations] = useState(0);
  const [mostActiveUser, setMostActiveUser] = useState(null);
  const [topPerformers, setTopPerformers] = useState([]);

  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        const res = await Api.get('/api/admin/translationStats');
        setTotalTranslations(res.data.totalTranslations);
        setMostActiveUser(res.data.mostActiveUser);
        setTopPerformers(res.data.topPerformers);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Failed to fetch dashboard data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="container mx-auto mt-8 p-4">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Total Translations */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Total Translations</h3>
          <p className="text-3xl font-bold text-blue-500">{totalTranslations}</p>
        </div>

        {/* Most Active User */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Most Active User</h3>
          {mostActiveUser ? (
            <div>
              <p className="text-gray-600">
                <strong>Username:</strong> {mostActiveUser.username}
              </p>
              <p className="text-gray-600">
                <strong>Email:</strong> {mostActiveUser.email}
              </p>
              <p className="text-gray-600">
                <strong>Translations:</strong> {mostActiveUser.count}
              </p>
            </div>
          ) : (
            <p className="text-gray-600">No data available</p>
          )}
        </div>

        {/* Top Performers */}
        <div className="bg-white shadow-md rounded-lg p-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Top 5 Performers</h3>
          {topPerformers.length > 0 ? (
            <ul className="list-disc pl-6 text-gray-600">
              {topPerformers.map((performer, index) => (
                <li key={index}>
                  <strong>{performer.username}</strong> ({performer.email}) - {performer.count} translations
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TranslationStats;