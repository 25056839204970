import React, { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, X, Search } from "lucide-react"; // Import Search icon
import "./FullScreenDropdown.css";

const FullScreenDropdown = ({
  dataSource = [],
  fields = { id: "id", text: "text" },
  placeholder = "Select an option",
  onSelected,
  label = "Choose an option:",
  selectedValue = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(dataSource);
  const [activeLetter, setActiveLetter] = useState(null);
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null); // Add ref for search input

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (dataSource.length > 0) {
      let filtered = dataSource.filter((item) =>
        item[fields.text].toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (activeLetter) {
        filtered = filtered.filter((item) => item[fields.text].startsWith(activeLetter));
      }
      setFilteredData(filtered);
    } else setFilteredData([]);
  }, [searchTerm, activeLetter, dataSource, fields.text]);

  useEffect(() => {
    if(selectedValue){// Update selected when selectedValue changes
      const selectedItem = dataSource.find((item) => item[fields.id] === selectedValue[fields.id]);
      setSelected(selectedItem);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus(); // Focus on search input when dropdown opens
    }
  }, [isOpen]);

  const handleSelect = (item) => {
    setSelected(item);
    setIsOpen(false);
    if (onSelected) {
      onSelected(item);
    }
  };

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      {label && <label htmlFor="dropdown-button" className="dropdown-label">{label}</label>}
      <button
        id="dropdown-button"
        className="dropdown-button"
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label="Dropdown selection"
      >
        <span className="dropdown-selected">
          {selected ? selected[fields.text] : placeholder}
        </span>
        <ChevronDown className="dropdown-icon" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="dropdown-overlay"
          >
            <div className="dropdown-content" role="dialog" aria-labelledby="dropdown-title">
              
              {/* Popup Header: Close button & Title */}
              <div className="dropdown-header">
                
                {/* Search Box */}
                <div className="dropdown-search">
                  <Search className="search-icon" size={18} />
                  <input
                    id="dropdown-search"
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-box"
                    aria-label="Search options"
                    ref={searchInputRef} // Add ref to search input
                  />
                </div>
                <button className="dropdown-close" onClick={() => setIsOpen(false)} aria-label="Close dropdown">
                  <X size={24} />
                </button>
              </div>

              {/* Alphabet Filter */}
              <div className="dropdown-alphabet">
                {alphabet.map((letter) => (
                  <button
                    key={letter}
                    className={`alphabet-btn ${activeLetter === letter ? "active" : ""}`}
                    onClick={() => setActiveLetter(letter === activeLetter ? null : letter)}
                    aria-label={`Filter by letter ${letter}`}
                  >
                    {letter}
                  </button>
                ))}
              </div>

              {/* Data List */}
              <div className="dropdown-grid" role="listbox">
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <div
                      key={item[fields.id]}
                      className="dropdown-item"
                      onClick={() => handleSelect(item)}
                      role="option"
                      aria-selected={selected === item}
                    >
                      {item[fields.text]}
                    </div>
                  ))
                ) : (
                  <p className="dropdown-no-results" aria-live="polite">No results found.</p>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FullScreenDropdown;