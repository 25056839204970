import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill"; // Import Quill separately
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react"; // Import Image Resize Module
import "./editorStyles.css";
import { Printer, Save, Info, Palette, X } from "lucide-react"; // Import Lucide React icons
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

Quill.register("modules/imageResize", ImageResize);

const pageSizes = {
  A4: { width: "210mm", height: "297mm" },
  Letter: { width: "216mm", height: "279mm" },
  Legal: { width: "216mm", height: "356mm" },
  Executive: { width: "184mm", height: "267mm" },
  Tabloid: { width: "279mm", height: "432mm" },
  A3: { width: "297mm", height: "420mm" },
  A5: { width: "148mm", height: "210mm" },
  B4: { width: "250mm", height: "353mm" },
  B5: { width: "176mm", height: "250mm" },
  Statement: { width: "140mm", height: "216mm" },
};

const LanguageCode = {
  ENGLISH: "en", AMHARIC: "am", ARABIC: "ar", BENGALI: "bn", CHINESE: "zh", GREEK: "el", GUJARATI: "gu",
  HINDI: "hi", KANNADA: "kn", MALAYALAM: "ml", MARATHI: "mr", NEPALI: "ne", ORIYA: "or", PERSIAN: "fa", PUNJABI: "pa",
  RUSSIAN: "ru", SANSKRIT: "sa", SINHALESE: "si", SERBIAN: "sr", TAMIL: "ta", TELUGU: "te", TIGRINYA: "ti", URDU: "ur",
};

const rtlLanguages = [
  "Arabic", "Hebrew", "Aramaic", "Dhivehi", "Sorani", "Persian", "Urdu", "Sindhi", "Pashto", "Yiddish",];

const googleDocFonts = [
  "Arial", "Calibri", "Cambria", "Comic Sans MS", "Courier New", "Georgia", "Impact", "Lucida Console", "Lucida Sans Unicode",
  "Microsoft Sans Serif", "Palatino Linotype", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana", "Abadi MT Condensed Extra Bold",
  "Abadi MT Condensed Light", "Algerian", "Andalus", "Angsana New", "AngsanaUPC", "Aparajita", "Arabic Typesetting", "Arial Black", "Arial Narrow",
  "Baskerville Old Face", "Batang", "Bell MT", "Berlin Sans FB", "Berlin Sans FB Demi", "Bernard MT Condensed", "Blackadder ITC", "Bodoni MT", "Bodoni MT Black", "Bodoni MT Condensed",
  "Bodoni MT Poster Compressed", "Book Antiqua", "Bookman Old Style", "Bradley Hand ITC", "Britannic Bold", "Broadway", "Brush Script MT", "Californian FB", "Candara",
  "Castellar", "Centaur", "Century Gothic", "Century Schoolbook", "Chiller", "Colonna MT", "Consolas", "Constantia", "Copperplate Gothic Bold",
  "Copperplate Gothic Light", "Corbel", "Cordia New", "CordiaUPC", "Courier", "Curlz MT", "DaunPenh", "David", "DilleniaUPC", "DokChampa", "Ebrima", "Edwardian Script ITC", "Eras Medium ITC",
  "Estrangelo Edessa", "EucrosiaUPC", "Felix Titling", "Footlight MT Light", "Forte", "Garamond", "Gautami", "Gisha", "Gloucester MT Extra Condensed", "Goudy Old Style", "Goudy Stout",
  "Gulim", "Haettenschweiler", "Harlow Solid Italic", "Harrington", "High Tower Text", "HoloLens MDL2 Assets", "Impact", "Imprint MT Shadow", "Informal Roman", "Ink Free", "Javanese Text",
  "Jokerman", "Juice ITC", "Kalinga", "Kartika", "Khmer UI", "Kristen ITC", "Kunstler Script", "Latha", "Leelawadee UI", "Lucida Bright", "Lucida Calligraphy", "Lucida Fax", "Lucida Handwriting",
  "Lucida Sans", "Lucida Sans Typewriter", "Magneto", "Maiandra GD", "Malgun Gothic", "Mangal", "Marlett", "Matura MT Script Capitals", "Meiryo", "Meiryo UI",
  "Microsoft Himalaya", "Microsoft JhengHei", "Microsoft YaHei", "Microsoft YaHei UI", "Microsoft Yi Baiti", "MingLiU-ExtB", "MingLiU_HKSCS", "Miriam", "Miriam Fixed",
  "Modern No. 20", "Mongolian Baiti", "Monotype Corsiva", "MS Gothic", "MS Mincho", "MS PGothic", "MS UI Gothic", "MV Boli", "Myanmar Text", "Nirmala UI", "OCR A Extended",
  "Old English Text MT", "Onyx", "Palatino Linotype", "Papyrus", "Poor Richard", "Pristina", "Rage Italic", "Ravenscroft", "Roboto", "Rockwell", "Rockwell Condensed", "Rockwell Extra Bold",
  "Rod", "Sakkal Majalla", "Segoe UI Semibold", "Segoe UI Semilight", "Segoe UI Symbol", "Shonar Bangla", "SimHei", "Simplified Arabic",
  "SimSun", "SimSun-ExtB", "Sistroen", "SketchFlow Grotesque", "Small Fonts", "Snap ITC", "Stencil", "Sylfaen", "Symbol", "Trebuchet MS",
  "Tunga", "Tw Cen MT", "Tw Cen MT Condensed", "Tw Cen MT Condensed Extra", "Ubuntu", "Ubuntu Mono", "Verdana", "Vijaya", "Viner Hand ITC", "Vivaldi", "Vladimir Script",
];

const AboutModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>About The Editor</h2>
          <X className="close-btn" size={20} onClick={onClose}/>
        </div>
        <div class="modal-body">
    <h2>Multi-Language Text Editor</h2>

    <h2>1. Type in Multiple Languages</h2>
    <ul>
        <li>You can type in any Script like English, Hindi, Arabic, Tamil, and more.</li>
        <li>The editor automatically adjusts text direction (Right to left and left to right )based on the selected language.</li>
    </ul>

    <h2>2. Easy Text Formatting</h2>
    <ul>
        <li>Choose from a variety of fonts</li>
        <li>Change font size, color, background, and apply bold, italic, underline, and more.</li>
        <li>Insert and style images, links, and code blocks.</li>
    </ul>

    <h2>3. Customize Page Layout</h2>
    <ul>
        <li>Supports different paper sizes like A4, A5, Letter, and more.</li>
        <li>Choose page in portrait or landscape mode.</li>
        <li>Gives a print-friendly document layout.</li>
    </ul>

    <h2>4. Insert & Resize Images</h2>
    <ul>
        <li>Resize images easily within your document.</li>
        <li>Drag and drop images directly into the editor.</li>
    </ul>

    <h2>5. Save & Print Documents</h2>
    <ul>
        <li>Save your work as PDF file, TXT file and HTML file.</li>
        <li>Print-ready formatting with proper margins and layout automatically.</li>
    </ul>

    <h2>6. User-Friendly Experience</h2>
    <ul>
        {/* <li>Dark and light mode for comfortable reading.</li> */}
        {/* <li>Helpful tooltips explain each feature.</li> */}
        <li>Instant preview of your changes.</li>
    </ul>

    <h2>7. Simple & Intuitive Interface</h2>
    <ul>
        <li>Easy-to-use toolbar with icons and dropdowns.</li>
        <li>Popup page for "About the Editor."</li>
    </ul>

    <p>This editor is great for writers, students, and professionals who need a powerful yet simple tool for writing, formatting, and managing documents.</p>


    <h2>Invitation to Developers</h2>
<p>
  We are excited to invite developers who are passionate about <strong>Text Editor </strong> 
   and innovative productivity tools to collaborate with us on this open-source project. 
  Your contributions can help enhance this editor, making it more powerful, user-friendly, 
  and efficient for  Learners, writers and creators worldwide.
</p>
<p>
  If you're interested in contributing, please visit our<a href="https://github.com/atozats/Gtongue_MERN.git"><button style={{ color: "blue", background: "none", cursor: "pointer" }}>GitHub repository</button>
  </a>to get started. 
  We welcome developers of all skill levels and backgrounds to join us on this journey. 
  Together, we can build a cutting-edge text editor that empowers users to work smarter 
  and more effectively.
</p>

      <br/>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
  <button style={{ color: "white", background: "green", border: "1px solid black", padding: "0px 13px 2px", cursor: "pointer"  }}>
    Join us on GitHub
  </button>

</div>
  <br/>  <br/>
</div>

      </div>
    </div>
  );
};


const Transliterate = ({ language_script, onTextChange }) => {
  const [lanControl, setLanControl] = useState(null);
  const quillRef = useRef(null);
  // const [text, setText] = useState("");
  const [font, setFont] = useState("Arial");
  const [fontSize, setFontSize] = useState("16px");
  const [customFontSize, setCustomFontSize] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [fileName, setFileName] = useState("Untitled Document");
  const [color, setColor] = useState("#000000");
  const [content, setContent] = useState([""]); // Ensure content is an array
  const [pageSize, setPageSize] = useState("A4");
  const [orientation, setOrientation] = useState("portrait");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleColorChange = (event) => {
    setColor(event.target.value);
    if (quillRef.current?.editor?.root) {
      quillRef.current.editor.root.style.color = event.target.value;
    }
  };

  const handleContentChange = (html, pageIndex) => {
    const newContent = [...content];
    newContent[pageIndex] = html;
    setContent(newContent);
    onTextChange(newContent.join("\n"));
    checkForOverflow();
  };

  useEffect(() => {
    const loadGoogleApi = () => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/jsapi";
      script.async = true;
      script.onload = () => {
        if (window.google && window.google.load) {
          window.google.load("elements", "1", {
            packages: "transliteration",
            callback: initializeTransliteration,
          });
        }
      };
      document.body.appendChild(script);
    };
    
    if (!window.google || !window.google.elements) {
      loadGoogleApi();
    } else {
      initializeTransliteration();
    }
  }, []);

  const initializeTransliteration = () => {
    if (
      !window.google ||
      !window.google.elements ||
      !window.google.elements.transliteration
    ) {
      console.error(
        "Google Transliteration API not available yet. Retrying..."
      );
      setTimeout(initializeTransliteration, 500);
      return;
    }

    const targetLanguageCode = language_script
      ? LanguageCode[language_script.toUpperCase()] || "hi"
      : "hi";

    const options = {
      sourceLanguage: "en",
      destinationLanguage:
        targetLanguageCode === "en" ? ["hi"] : [targetLanguageCode],
      shortcutKey: "ctrl+g",
      transliterationEnabled: targetLanguageCode !== "en" && !!language_script,
    };

    const control =
      new window.google.elements.transliteration.TransliterationControl(
        options
      );

    // Ensure Quill editor allows direct input
    const editorContainer = quillRef.current?.editor?.root;

    if (editorContainer) {
      control.makeTransliteratable([editorContainer]);
      if(language_script) control.enableTransliteration(); // Enable transliteration explicitly
    } else {
      console.error("Editor container not found for transliteration.");
    }

    setLanControl(control);
  };


  const isLanTransliterable = (lan_code) => {
    return Object.values(LanguageCode).includes(lan_code);
  }

  useEffect(() => {
    if (lanControl) {
      const targetLanguageCode = language_script && isLanTransliterable(language_script)
        ? language_script
        : "hi";

      if (language_script && targetLanguageCode && targetLanguageCode !== "en") {
        lanControl.setLanguagePair("en", targetLanguageCode);
        lanControl.enableTransliteration();
      } else {
        lanControl.disableTransliteration();
      }
    }

    if (quillRef.current?.editor?.root) {
      const { width, height } = pageSizes[pageSize];
      quillRef.current.editor.root.dir = rtlLanguages.includes(language_script)
        ? "rtl"
        : "ltr";
      quillRef.current.editor.root.style.fontFamily = font;
      quillRef.current.editor.root.style.fontSize = fontSize;
      quillRef.current.editor.root.style.width =
        orientation === "portrait" ? width : height;
      quillRef.current.editor.root.style.minHeight =
        orientation === "portrait" ? height : width;
      quillRef.current.editor.root.style.maxHeight = "none";
      quillRef.current.editor.root.style.overflow = "auto";
      quillRef.current.editor.root.style.padding = "10mm";

    }
  }, [language_script, lanControl, font, fontSize, pageSize, orientation]);


  
  const saveDocument = async () => {
    try {
        if (!window.showSaveFilePicker) {
            alert("Your browser does not support the Save File Picker API.");
            return;
        }

        // Get the file extension
        let extension = "html"; // Default file type
        const quillEditor = quillRef.current?.getEditor();
        const rawHtmlContent = quillEditor ? quillEditor.root.innerHTML : "<p>No content</p>";
        const plainTextContent = quillEditor ? quillEditor.getText() : "No content";

        // Show save file dialog with default file name
        const fileHandle = await window.showSaveFilePicker({
            suggestedName: `${fileName}.html`, // Use the document name as file name
            types: [
                { description: "HTML Document", accept: { "text/html": [".html"] } },
                { description: "Text Document", accept: { "text/plain": [".txt"] } },
                { description: "PDF Document", accept: { "application/pdf": [".pdf"] } },
            ]
        });

        // Get selected file extension
        const selectedFileName = fileHandle.name;
        extension = selectedFileName.split('.').pop().toLowerCase();

        let blob;

        if (extension === "pdf") {
            const editorElement = document.querySelector(".ql-editor");
            if (!editorElement) {
                alert("Editor content not found!");
                return;
            }

            const canvas = await html2canvas(editorElement, { scale: 2 });
            const imageData = canvas.toDataURL("image/png");

            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imageData, "PNG", 0, 0, imgWidth, imgHeight);
            blob = pdf.output("blob");
        } else if (extension === "txt") {
            blob = new Blob([plainTextContent], { type: "text/plain" });
        } else {
            blob = new Blob([rawHtmlContent], { type: "text/html" });
        }

        await saveBlobToFile(fileHandle, blob);
    } catch (error) {
        console.error("Error saving file:", error);
    }
};

  
  // Function to write the file properly
  const saveBlobToFile = async (fileHandle, blob) => {
    const writable = await fileHandle.createWritable();
    await writable.write(blob);
    await writable.close();
  };
  
  
  const handleSelectChange = (e) => {
    if (e.target.value === "custom") {
      setIsCustom(true);
    } else {
      setIsCustom(false);
      setFontSize(e.target.value);
    }
  };

  const handleCustomChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    setCustomFontSize(value);
    if (value) {
      setFontSize(`${value}px`);
    }
  };


  const printDocument = () => {
    const printWindow = window.open("", "", "width=800,height=600");
    const { width, height } = pageSizes[pageSize];
    const printWidth = orientation === "portrait" ? width : height;
    const printHeight = orientation === "portrait" ? height : width;

    printWindow.document.write(`
      <html>
      <head>
        <title>Print Document</title>
        <style>
          @media print {
            @page {
              size: ${printWidth} ${printHeight};
              margin: 20mm;
            }
            body {
              margin: 0;
              padding: 0;
            }
            .ql-editor {
              width: calc(100% - 40mm);
              min-height: calc(100% - 40mm);
              margin: 20mm;
              padding: 0;
            }
          }
        </style>
      </head>
      <body>
        <div class="ql-editor">${content}</div>
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const checkForOverflow = () => {
    const pages = document.querySelectorAll(".page-container");
    if (pages.length === 0) return;

    pages.forEach((page, index) => {
      const pageHeight = page.clientHeight;
      const contentHeight = page.scrollHeight;

      // If the content exceeds the current page height
      if (contentHeight > pageHeight) {
        const editor = page.querySelector(".ql-editor");
        const contentNodes = Array.from(editor.childNodes);
        let newPageContent = [];
        let currentHeight = 0;

        for (let node of contentNodes) {
          currentHeight += node.scrollHeight;
          if (currentHeight > pageHeight) {
            newPageContent.push(node);
          }
        }

        if (newPageContent.length > 0) {
          // Remove the overflowing content from the current page
          newPageContent.forEach((node) => editor.removeChild(node));

          // Create a new page
          setContent((prevContent) => [...prevContent, newPageContent.map(n => n.outerHTML).join(" ")]);
        }
      }
    });
  };

  useEffect(() => {
    checkForOverflow();
  }, [content]);

  // Ensure pages are not scrollable
  document.querySelectorAll(".ql-editor").forEach((editor) => {
    editor.style.overflow = "hidden";
  });

  // Allow user to select different paper sizes and apply dynamically
  useEffect(() => {
    const paperSizeSelector = document.querySelector("#paperSizeSelector");
    if (!paperSizeSelector) return;

    const handlePaperSizeChange = (event) => {
      const selectedSize = event.target.value;
      const { width } = pageSizes[selectedSize] || pageSizes.A4;
      document.querySelectorAll(".ql-editor").forEach((editor) => {
        editor.style.width = width;
        editor.style.minHeight = "auto";
        editor.style.maxHeight = "none";
      });
    };

    paperSizeSelector.addEventListener("change", handlePaperSizeChange);
    return () => paperSizeSelector.removeEventListener("change", handlePaperSizeChange);
  }, []);


  useEffect(() => {
    // Add tooltips to all Quill toolbar buttons
    const addTooltips = () => {
      const toolbar = document.querySelector('.ql-toolbar');
      if (!toolbar) return;

      const tooltipMap = {
        'ql-bold': 'Bold (Ctrl+B)',
        'ql-italic': 'Italic (Ctrl+I)',
        'ql-underline': 'Underline (Ctrl+U)',
        'ql-strike': 'Strikethrough',
        'ql-color': 'Text Color',
        'ql-background': 'Background Color',
        'ql-script': 'Superscript/Subscript',
        'ql-list': 'List',
        'ql-indent': 'Indentation',
        'ql-align': 'Text Alignment',
        'ql-blockquote': 'Block Quote',
        'ql-code-block': 'Code Block',
        'ql-link': 'Insert Link',
        'ql-image': 'Insert Image',
        'ql-undo': 'Undo (Ctrl+Z)',
        'ql-redo': 'Redo (Ctrl+Y)',
        'ql-clean': 'Clear Formatting',
        'ql-font': 'Font Family',
        'ql-size': 'Font Size'
      };

      // Button tooltips
      const buttons = toolbar.querySelectorAll('button');
      buttons.forEach(button => {
        const cls = Array.from(button.classList).find(c => c in tooltipMap);
        if (cls) button.title = tooltipMap[cls];
      });

      // Dropdown labels
      const labels = toolbar.querySelectorAll('.ql-picker-label');
      labels.forEach(label => {
        const cls = Array.from(label.classList).find(c => c in tooltipMap);
        if (cls) label.title = tooltipMap[cls];
      });
    };

    // Run after editor initialization
    const timeoutId = setTimeout(addTooltips, 500);
    return () => clearTimeout(timeoutId);
  }, []);

 


  return (


    <div className="editor-container">
<button className="abt" onClick={() => setIsModalOpen(true)}  title='About the Editor Tool'>
    <Info className="icon" /> About the Editor
      </button>
    <AboutModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className="toolbar">
        <label>Document Name: </label>
        <input
          type="text"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter document name"
        />
        <label>Font: </label>
        <select value={font} onChange={(e) => setFont(e.target.value)}>
          {[...new Set(googleDocFonts)].map((fontName) => (
            <option key={fontName} value={fontName}>
              {fontName}
            </option>
          ))}
        </select>

        <label>Page Size: </label>
        <select value={pageSize} onChange={(e) => setPageSize(e.target.value)}>
          {Object.keys(pageSizes).map((size) => (
            <option key={size} value={size}>{size}</option>
          ))}
        </select>

        <label>Orientation: </label>
        <select value={orientation} onChange={(e) => setOrientation(e.target.value)}>
          <option value="portrait">Portrait</option>
          <option value="landscape">Landscape</option>
        </select>


       <div>
      <label>Font Size: </label>
      <select value={isCustom ? "custom" : fontSize} onChange={handleSelectChange}>
        <option value="12px">12</option>
        <option value="14px">14</option>
        <option value="16px">16</option>
        <option value="18px">18</option>
        <option value="20px">20</option>
        <option value="24px">24</option>
        <option value="28px">28</option>
        <option value="32px">32</option>
        <option value="36px">36</option>
        <option value="40px">40</option>
        <option value="custom">Custom</option>
      </select>

      {isCustom && (
        <input
          type="text"
          value={customFontSize}
          onChange={handleCustomChange}
          placeholder="Enter size"
          style={{ width: "50px", marginLeft: "10px" }}
        />
      )}
    
    </div>


        <div id="color-picker" title="Text Color">
    Text Color: 
    <div className="color-display" style={{ backgroundColor: color }}></div>
    <input type="color" value={color} onChange={handleColorChange} />
  </div>
  
        <button onClick={saveDocument} className="icon-button" title="Save Document">
        <Save size={20} /> Save
        </button>
        <button onClick={printDocument} className="icon-button" title="Print Document">
        <Printer size={20} /> Print
        </button>
        <div id="toolbar">
          <select className="ql-font" />
          <select className="ql-size" />
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
          <button className="ql-link" />
          <button className="ql-image" />
          <button className="ql-clean" />
          <button className="ql-script" value="sub" />
          <button className="ql-script" value="super" />
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-list" value="check" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
          <select className="ql-color" />
          <select className="ql-background" />
        </div>
      </div>

      <div className="document-wrapper">
        {Array.isArray(content) && content.map((pageContent, index) => (
          <div key={index} className="page-container">
            <ReactQuill
              ref={index === content.length - 1 ? quillRef : null}
              value={pageContent}
              onChange={(html) => handleContentChange(html, index)}
              modules={{
                toolbar: "#toolbar",
                history: {
                  delay: 500,
                  maxStack: 100,
                  userOnly: true
                },
                imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'],
    displayStyles: {
      backgroundColor: 'black', // Default background
      border: '2px solid white' // Border styling
    },
    handleStyles: {
      backgroundColor: 'white',
      border: '1px solid black'
    },
    align: ['left', 'center', 'right', 'behind-text'], // Adding new option
  },
              }}
              formats={[
                "font", "size", "bold", "italic", "underline", "strike",
                "color", "background", "script", "list", "bullet", "check",
                "indent", "align", "blockquote", "code-block", "link", "image"
              ]}
            />
          </div>
        ))}
      </div>
    </div>

  );
};

export default Transliterate;