import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useLoading } from './Contexts/LoadingContext';
import Spinner from './components/Spinner';
import ProtectedRoute, { AdminRoute } from './Utils/ProtectedRoute';
import TranslatePage from './pages/TranslatePage/TranslatePage';
import ContributionPage from './pages/ContributionPage/ContributionPage';
import Navbar from './components/Navbar/Navbar';
import HomePage from './pages/HomePage/HomePage';
import AuthPage from './pages/Auth/AuthPage';
import AdminDashboard from './pages/Admin/Dashboard';
import LearnHome from './pages/Learn/LearnHome';
import DialoguePage from './pages/Learn/DialoguePage';
import { EditorPage } from './pages/EditorPage/EditorPage';
import About from './pages/About/AboutUs';
import CLASign from './pages/CLA/claSign';
import './App.css';
import ProfilePage from './pages/ProfilePage/ProfilePage';

function App() {

    const { isLoading } = useLoading();

    useEffect(() => {
        const container = document.querySelector(".floating-unicode-container");
        const unicodeChars = [
            // Latin
            ..."ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            // Indic (Devanagari - Hindi, Sanskrit)
            ..."अआइईउऊऋएऐओऔकखगघचछजझटठडढणतथदधनपफबभमयरलवशषसह",
            // Indic (Bengali)
            ..."অআইঈউঊঋএঐওঔকখগঘঙচছজঝঞটঠডঢণতথদধনপফবভমযরলশষসহ",
            // Indic (Tamil)
            ..."அஆஇஈஉஊஎஏஐஒஓஔகஙசஞடணதநபமயரலவழளஷஸஹ",
            // Indic (Telugu)
            ..."అఆఇఈఉఊఋఎఏఐఒఓఔకఖగఘఙచఛజఝఞటఠడఢణతథదధనపఫబభమయరలవశషసహ",
            // Indic (Kannada)
            ..."ಅಆಇಈಉಊಋಎಏಐಒಓಔಕಖಗಘಙಚಛಜಝಞಟಠಡಢಣತಥದಧನಪಫಬಭಮಯರಲವಶಷಸಹ",
            // Indic (Malayalam)
            ..."അആഇഈഉഊഋഎഏഐഒഓഔകഖഗഘങചഛജഝഞടഠഡഢണതഥദധനപഫബഭമയരലവശഷസഹ",
            // Indic (Gujarati)
            ..."અઆઇઈઉઊઋએઐઓઔકખગઘચછજઝટઠડઢણતથદધનપફબભમયરલવશષસહ",
            // Indic (Punjabi - Gurmukhi)
            ..."ਅਆਇਈਉਊਏਐਓਔਕਖਗਘਙਚਛਜਝਟਠਡਢਣਤਥਦਧਨਪਫਬਭਮਯਰਲਵਸ਼ਸਹ",
            // Mandarin (Chinese)
            ..."你好学习语言中文字汉语拼音书写",
            // Arabic
            ..."ابتثجحخدذرزسشصضطظعغفقكلمنهوي",
            // Cyrillic
            ..."АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ",
            // Greek
            ..."ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ",
            // Japanese (Hiragana + Katakana)
            ..."あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン",
            // Korean (Hangul)
            ..."가나다라마바사아자차카타파하",
            // Hebrew
            ..."אבגדהוזחטיכלמנסעפצקרשת",
            // Thai
            ..."กขคฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ",
        ];

        const createScatteredChar = () => {
            const char = document.createElement("div");
            char.className = "floating-unicode";
            char.textContent = unicodeChars[Math.floor(Math.random() * unicodeChars.length)];
            char.style.left = `${Math.random() * 100}vw`;
            char.style.top = `${Math.random() * 100}vh`;
            char.style.fontSize = `${1 + Math.random() * 2}rem`;
            container.appendChild(char);
        };

        // Generate scattered characters
        for (let i = 0; i < 50; i++) {
            createScatteredChar();
        }
    }, []);

    return (
        <>
            {isLoading && <Spinner />}

            {/* Background Image */}
            {/* Floating Unicode Background */}
            <div className="floating-unicode-container"></div>

            <Navbar>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/home' element={<HomePage />} />
                    <Route path='/translate' element={<TranslatePage />} />
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path='/editor' element={<EditorPage />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/CLA' element={<CLASign />} />

                    {/* Protected Routes */}
                    <Route path='/profile' element={
                        <ProtectedRoute>
                            <ProfilePage />
                        </ProtectedRoute>} />
                    <Route path='/contribute' element={
                        <ProtectedRoute>
                            <ContributionPage />
                        </ProtectedRoute>} />
                    <Route path="/learn" element={
                        <ProtectedRoute>
                            <LearnHome />
                        </ProtectedRoute>} />
                    <Route path='/learn/dialogue/:language' element={
                        <ProtectedRoute>
                            <DialoguePage />
                        </ProtectedRoute>} />

                    {/* Admin Routes */}
                    <Route path="/admin" element={
                        <AdminRoute >
                            <AdminDashboard />
                        </AdminRoute>} />

                    {/* <Route path='/dashboard' element={<Dashboard />} /> */}
                    { /*<Route path='/translations' element={<TranslationList />} /> */}
                    { /*<Route path='/languages' element={<LanguageList />} /> */}
                </Routes>

                <ToastContainer position="top-right" />

            </Navbar> </>
    );
}

export default App;