import React, { useEffect, createContext, useState, useMemo, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null); // null indicates loading state
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Function to refresh the access token
  const refreshAccessToken = useCallback(async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        logout();
        return;
      }

      const response = await axios.post("/api/auth/refresh-token", { token: refreshToken });
      const { accessToken, refreshToken: newRefreshToken } = response.data;

      // Save the new tokens
      localStorage.setItem("token", accessToken);
      localStorage.setItem("refreshToken", newRefreshToken);

      // Decode the new access token and update the state
      const decodedLoginData = jwtDecode(accessToken);
      setUser(decodedLoginData.user);
      setIsLoggedIn(true);
      setIsAdmin(decodedLoginData?.user?.isAdmin || false);
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      logout(); // Logout if refreshing fails
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decodedLoginData = jwtDecode(token);
        setUser(decodedLoginData.user);
        setIsLoggedIn(true);
        setIsAdmin(decodedLoginData?.user?.isAdmin || false);

        // Check if the token is about to expire and refresh it
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedLoginData.exp - currentTime < 300) {
          // If the token will expire in less than 5 minutes, refresh it
          refreshAccessToken();
        }
      } catch (error) {
        console.error("Invalid token:", error);
        logout(); // Clear invalid token
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [refreshAccessToken]);

  const login = useCallback((loginData) => {
    try {
      const decodedLoginData = jwtDecode(loginData.token);
      localStorage.setItem("token", loginData.token);
      localStorage.setItem("refreshToken", loginData.refreshToken);
      setIsLoggedIn(true);
      setUser(decodedLoginData.user);
      setIsAdmin(decodedLoginData?.user?.isAdmin || false);
    } catch (error) {
      console.error("Login error:", error);
    }
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUser(null);
    setIsAdmin(false);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  }, []);

  // ✅ Memoizing the value to prevent unnecessary re-renders
  const authState = useMemo(
    () => ({ isLoggedIn, user, isAdmin, login, logout, refreshAccessToken }),
    [isLoggedIn, user, isAdmin, login, logout, refreshAccessToken]
  );

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};